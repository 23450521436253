import React, { type ReactElement, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useApiPost } from '../../../hooks/api/useApiPost'
import {
  API_TEACHER_LESSON_GRADES_EDIT_PATH,
  API_TEACHER_LESSON_GRADES_PATH,
  API_TEACHER_LESSON_GRADE_GROUPS_PATH,
  API_TEACHER_LESSON_GRADE_GROUPS_EDIT_PATH,
  API_TEACHER_LESSON_GRADE_TEMPLATES_PATH,
  API_TEACHER_LESSON_GRADE_TEMPLATES_EDIT_PATH
} from '../../../constants/apiRoutes'
import type { GradeData, GradeTemplate, TeacherLessonGradesProps, StudentData, LessonData } from '../../../constants/types'
import GradeGroupModal from './modals/GradeGroupModal'
import GradeTemplateModal from './modals/GradeTemplateModal'
import GradeEditModal from './modals/GradeEditModal'
import TeachersList from './TeachersList'
import AddIcon from './icons/AddIcon'
import EditIcon from './icons/EditIcon'
import ChevronIcon from './icons/ChevronIcon'
import ClassUnitsList from './ClassUnitsList'
import StudentCell from './StudentCell'
import GradeCell from './GradeCell'
import { findStudentGrade } from './gradeUtils'

const TeacherLessonGrades = ({ categories, fetchLessonData, fetchGradesData, lessonId }: TeacherLessonGradesProps): ReactElement => {
  const { t } = useTranslation('translations')

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [students, setStudents] = useState<StudentData[]>([])
  const [collapsedCategories, setCollapsedCategories] = useState<Record<number, boolean>>({})
  const [groupName, setGroupName] = useState('')
  const [lesson, setLesson] = useState<LessonData | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false)
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null)
  const [templateName, setTemplateName] = useState('')
  const [isGradeEditOpen, setIsGradeEditOpen] = useState(false)
  const [pendingGradeUpdate, setPendingGradeUpdate] = useState(false)
  const [lastKeyPressed, setLastKeyPressed] = useState<string>('')
  const [editingGroupId, setEditingGroupId] = useState<number | null>(null)
  const [editingTemplateId, setEditingTemplateId] = useState<number | null>(null)
  const [selectedGrade, setSelectedGrade] = useState<{
    id?: number
    studentId: number
    gradeTemplateId: number
    gradeType: string
    value?: string
    note?: string
  } | null>(null)
  const [editingCell, setEditingCell] = useState<{
    studentId: number
    templateId: number
    value: string
  } | null>(null)

  const groupNameRef = useRef(groupName)

  const { sendData: createGradeGroup } = useApiPost({
    url: API_TEACHER_LESSON_GRADE_GROUPS_PATH.replace(':id', lessonId.toString()),
    successFlashMessage: 'Grupa ocen została dodana'
  })

  const { sendData: createGradeTemplate } = useApiPost({
    url: API_TEACHER_LESSON_GRADE_TEMPLATES_PATH.replace(':id', lessonId.toString()),
    successFlashMessage: 'Szablon ocen został dodany'
  })

  const { sendData: updateGrade } = useApiPost({
    url: API_TEACHER_LESSON_GRADES_EDIT_PATH
      .replace(':lesson_id', lessonId.toString())
      .replace(':id', selectedGrade?.id?.toString() ?? ''),
    successFlashMessage: 'Ocena została zaktualizowana'
  })

  useEffect(() => {
    const loadStudents = async (): Promise<void> => {
      try {
        const response = await fetchLessonData()
        if (response) {
          setLesson(response)
          setStudents(response?.students)
        }
      } catch (error) {
        console.error('Failed to fetch lesson:', error)
      }
    }

    void loadStudents()
  }, [])

  useEffect(() => {
    const handlePendingUpdate = async (): Promise<void> => {
      if (pendingGradeUpdate && selectedGrade?.id && editingCell) {
        await updateGrade({
          method: 'patch',
          additionalParams: {
            student_id: selectedGrade.studentId,
            grade_template_id: selectedGrade.gradeTemplateId,
            grade_type: 'present',
            value: editingCell.value
          },
          callback: async () => {
            await fetchGradesData()
          }
        })

        setPendingGradeUpdate(false)
        setSelectedGrade(null)

        const nextStudentIndex = students.findIndex(s => s.id === editingCell.studentId) + 1
        const nextStudent = students[nextStudentIndex]

        if (nextStudent && lastKeyPressed === 'Enter') {
          setEditingCell({
            studentId: nextStudent.id,
            templateId: editingCell.templateId,
            value: ''
          })
        } else {
          setEditingCell(null)
        }
      }
    }

    void handlePendingUpdate()
  }, [selectedGrade, pendingGradeUpdate])

  useEffect(() => {
    const handleResize = (): void => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  }, [])

  const toggleCategory = (categoryId: number): void => {
    setCollapsedCategories(prev => ({
      ...prev,
      [categoryId]: !prev[categoryId]
    }))
  }

  const handleCreateGradeGroup = async (): Promise<void> => {
    await createGradeGroup({
      additionalParams: { name: groupName },
      callback: async () => {
        try {
          await fetchGradesData()
          setGroupName('')
          setIsModalOpen(false)
        } catch (error) {
          console.error('Error in callback:', error)
        }
      }
    })
  }

  const handleCreateGradeTemplate = async (): Promise<void> => {
    if (!selectedGroupId) return

    await createGradeTemplate({
      additionalParams: {
        name: templateName,
        grade_group_id: selectedGroupId
      },
      callback: async () => {
        try {
          await fetchGradesData()
          setTemplateName('')
          setIsTemplateModalOpen(false)
          setSelectedGroupId(null)
        } catch (error) {
          console.error('Error in callback:', error)
        }
      }
    })
  }

  const handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    groupNameRef.current = e.target.value
    setGroupName(groupNameRef.current)
  }

  const handleGradeClick = (student: StudentData, template: GradeTemplate, grade?: GradeData): void => {
    setIsGradeEditOpen(false)
    setSelectedGrade(null)

    setEditingCell({
      studentId: student.id,
      templateId: template.id,
      value: grade?.value ?? ''
    })
  }

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>, currentIndex: number, studentId: number): Promise<void> => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault()
      setLastKeyPressed(e.key)

      if (editingCell?.value) {
        const allTemplates = categories.flatMap(cat => cat.grade_templates)
        const template = allTemplates.find(t => t.id === editingCell.templateId)
        const existingGrade = template?.grades?.find(g => g.student.id === editingCell.studentId)

        if (e.key === 'Enter') {
          if (existingGrade?.id) {
            const gradeData = {
              id: existingGrade.id,
              studentId: editingCell.studentId,
              gradeTemplateId: editingCell.templateId,
              gradeType: existingGrade.grade_type ?? 'present',
              value: editingCell.value,
              note: existingGrade.note
            }

            setSelectedGrade(gradeData)

            await updateGrade({
              method: 'patch',
              apiUrl: API_TEACHER_LESSON_GRADES_EDIT_PATH
                .replace(':lesson_id', lessonId.toString())
                .replace(':id', existingGrade.id.toString()),
              additionalParams: {
                student_id: editingCell.studentId,
                grade_template_id: editingCell.templateId,
                grade_type: 'present',
                value: editingCell.value
              },
              callback: async () => {
                await fetchGradesData()
                setSelectedGrade(null)
              }
            })
          } else {
            await updateGrade({
              method: 'post',
              apiUrl: API_TEACHER_LESSON_GRADES_PATH.replace(':id', lessonId.toString()),
              additionalParams: {
                student_id: editingCell.studentId,
                grade_template_id: editingCell.templateId,
                grade_type: 'present',
                value: editingCell.value
              },
              callback: async () => {
                await fetchGradesData()
              }
            })
          }
        }

        const nextStudent = students[currentIndex + 1]
        if (nextStudent) {
          const nextStudentGrade = template?.grades?.find(g => g.student.id === nextStudent.id)

          setEditingCell({
            studentId: nextStudent.id,
            templateId: editingCell.templateId,
            value: nextStudentGrade?.value ?? ''
          })
        } else {
          setEditingCell(null)
        }
      } else {
        const nextStudent = students[currentIndex + 1]
        if (nextStudent) {
          const template = categories.flatMap(cat => cat.grade_templates)
            .find(t => t.id === editingCell?.templateId)
          const nextStudentGrade = template?.grades?.find(g => g.student.id === nextStudent.id)

          setEditingCell({
            studentId: nextStudent.id,
            templateId: editingCell?.templateId ?? 0,
            value: nextStudentGrade?.value ?? ''
          })
        } else {
          setEditingCell(null)
        }
      }
    }
  }

  const handleGradeChange = (value: string): void => {
    if (editingCell) {
      setEditingCell({ ...editingCell, value })
    }
  }

  const handleGradeUpdate = async (): Promise<void> => {
    if (!selectedGrade) return

    if (selectedGrade.gradeType === 'none' && selectedGrade.id) {
      await updateGrade({
        method: 'delete',
        apiUrl: API_TEACHER_LESSON_GRADES_EDIT_PATH
          .replace(':lesson_id', lessonId.toString())
          .replace(':id', selectedGrade.id.toString()),
        callback: async () => {
          await fetchGradesData()

          const currentStudentIndex = students.findIndex(s => s.id === selectedGrade.studentId)
          const nextStudent = students[currentStudentIndex + 1]

          if (nextStudent) {
            const allTemplates = categories.flatMap(cat => cat.grade_templates)
            const template = allTemplates.find(t => t.id === selectedGrade.gradeTemplateId)
            const nextStudentGrade = template?.grades?.find(g => g.student.id === nextStudent.id)

            setEditingCell({
              studentId: nextStudent.id,
              templateId: selectedGrade.gradeTemplateId,
              value: nextStudentGrade?.value ?? ''
            })
          } else {
            setEditingCell(null)
          }

          setIsGradeEditOpen(false)
          setSelectedGrade(null)
        }
      })
    } else {
      await updateGrade({
        method: selectedGrade.id ? 'patch' : 'post',
        apiUrl: selectedGrade.id
          ? API_TEACHER_LESSON_GRADES_EDIT_PATH
            .replace(':lesson_id', lessonId.toString())
            .replace(':id', selectedGrade.id.toString())
          : API_TEACHER_LESSON_GRADES_PATH.replace(':id', lessonId.toString()),
        additionalParams: {
          student_id: selectedGrade.studentId,
          grade_template_id: selectedGrade.gradeTemplateId,
          grade_type: selectedGrade.gradeType,
          ...(selectedGrade.value && { value: selectedGrade.value }),
          ...(selectedGrade.note && { note: selectedGrade.note })
        },
        callback: async () => {
          await fetchGradesData()

          const currentStudentIndex = students.findIndex(s => s.id === selectedGrade.studentId)
          const nextStudent = students[currentStudentIndex + 1]

          if (nextStudent) {
            const allTemplates = categories.flatMap(cat => cat.grade_templates)
            const template = allTemplates.find(t => t.id === selectedGrade.gradeTemplateId)
            const nextStudentGrade = template?.grades?.find(g => g.student.id === nextStudent.id)

            setEditingCell({
              studentId: nextStudent.id,
              templateId: selectedGrade.gradeTemplateId,
              value: nextStudentGrade?.value ?? ''
            })
          } else {
            setEditingCell(null)
          }

          setIsGradeEditOpen(false)
          setSelectedGrade(null)
        }
      })
    }
  }

  const handleDeleteGradeGroup = async (groupId: number): Promise<void> => {
    if (window.confirm('Czy na pewno chcesz usunąć tę grupę ocen?')) {
      await updateGrade({
        method: 'delete',
        apiUrl: API_TEACHER_LESSON_GRADE_GROUPS_EDIT_PATH
          .replace(':id', lessonId.toString()) + `/${groupId}`,
        callback: async () => {
          await fetchGradesData()
          setGroupName('')
          setIsModalOpen(false)
          setEditingGroupId(null)
        }
      })
    }
  }

  const handleDeleteGradeTemplate = async (templateId: number): Promise<void> => {
    if (window.confirm('Czy na pewno chcesz usunąć ten szablon ocen?')) {
      await updateGrade({
        method: 'delete',
        apiUrl: API_TEACHER_LESSON_GRADE_TEMPLATES_EDIT_PATH
          .replace(':id', lessonId.toString()) + `/${templateId}`,
        callback: async () => {
          await fetchGradesData()
          setTemplateName('')
          setIsTemplateModalOpen(false)
          setEditingTemplateId(null)
        }
      })
    }
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="text-left text-[32px] font-bold lg:block hidden">
          {t('grades.header')}
        </div>
      </div>
      <div className="lg:py-[30px] py-[20px] px-5">
        {lesson && <TeachersList lesson={lesson} t={t} />}
        {lesson && <ClassUnitsList lesson={lesson} t={t} />}
      </div>
      <div className="shadow-lg">
        <div className="bg-white p-4 border border-gray-200 lg:rounded-t-lg">
          <button
            type="button"
            className="text-blue-800 hover:text-white flex gap-2 justify-center py-2 px-4 border border-blue-800 rounded-lg hover:bg-blue-800 items-center"
            onClick={() => { setIsModalOpen(true) }}
          >
            {t('grades.add_grade_group')}
            <AddIcon />
          </button>
        </div>

        <GradeGroupModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false)
            setEditingGroupId(null)
          }}
          groupName={groupName}
          onGroupNameChange={handleGroupNameChange}
          onSave={() => { void handleCreateGradeGroup() }}
          onDelete={editingGroupId ? () => { void handleDeleteGradeGroup(editingGroupId) } : undefined}
          editingGroupId={editingGroupId}
        />

        <GradeTemplateModal
          isOpen={isTemplateModalOpen}
          onClose={() => { setIsTemplateModalOpen(false) }}
          templateName={templateName}
          onTemplateNameChange={(e) => { setTemplateName(e.target.value) }}
          onSave={() => { void handleCreateGradeTemplate() }}
          onDelete={editingTemplateId ? () => { void handleDeleteGradeTemplate(editingTemplateId) } : undefined}
          editingTemplateId={editingTemplateId}
        />

        <GradeEditModal
          isOpen={isGradeEditOpen}
          onClose={() => {
            setIsGradeEditOpen(false)
            setSelectedGrade(null)
          }}
          isMobile={isMobile}
          selectedGrade={selectedGrade}
          onGradeChange={setSelectedGrade}
          onSave={() => { void handleGradeUpdate() }}
          students={students}
          t={t}
        />

        <div className="max-w-[1400px]">
          <div className="w-full overflow-x-auto relative">
            <table className="w-full border-collapse bg-white rounded-lg shadow">
              <thead>
                <tr className="bg-gray-50">
                  <th
                    rowSpan={2}
                    className="sticky left-0 z-10 bg-gray-100 lg:px-5 px-2 py-3 text-left align-bottom text-sm font-semibold text-gray-500 border border-gray-200 lg:w-[300px] w-[220px] after:absolute after:top-0 after:right-[-8px] after:content-[''] after:w-[7px] after:h-full after:bg-gradient-to-r after:from-black/[.07] after:to-transparent"
                  >
                    {t('grades.name_surname')}
                  </th>
                  {categories.map(category => (
                    <th key={category.id}
                      className={`px-2 py-2 text-left text-sm text-gray-500 font-semibold border border-gray-200 ${collapsedCategories[category.id] ? 'bg-gray-100' : ''} relative`}
                      colSpan={collapsedCategories[category.id] ? 1 : category.grade_templates.length}
                      onMouseEnter={() => { !isModalOpen && setEditingGroupId(category.id) }}
                      onMouseLeave={() => { !isModalOpen && setEditingGroupId(null) }}
                    >
                      <div className="flex items-center gap-1 py-1 px-1">
                        <button
                          onClick={() => { toggleCategory(category.id) }}
                          className="p-1 hover:bg-gray-200 rounded"
                        >
                          <ChevronIcon collapsed={collapsedCategories[category.id]} />
                        </button>
                        <div className={`truncate transition-all duration-300 ease-in-out ${collapsedCategories[category.id] ? 'w-[50px]' : 'w-fit'}`}>
                          {category.name}
                        </div>
                        <button
                          onClick={(e) => {
                            e.stopPropagation()
                            setSelectedGroupId(category.id)
                            setIsTemplateModalOpen(true)
                          }}
                          className="p-1 hover:bg-gray-200 rounded"
                        >
                          <AddIcon />
                        </button>
                        {editingGroupId === category.id && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation()
                              setGroupName(category.name)
                              setEditingGroupId(category.id)
                              setIsModalOpen(true)
                            }}
                            className="p-1 hover:bg-gray-200 rounded"
                          >
                            <EditIcon />
                          </button>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
                <tr className="bg-gray-50">
                  {categories.map(category =>
                    collapsedCategories[category.id]
                      ? (
                        <th key={category.id} className="px-2 py-2 border border-gray-200 bg-gray-100 w-[60px]">
                        </th>
                      )
                      : (
                        category.grade_templates.map(template => (
                          <th key={template.id} className="px-4 py-3 text-left text-sm text-gray-500 font-medium border border-gray-200">
                            <div className="w-[150px] truncate group relative"
                              onMouseEnter={() => { !isTemplateModalOpen && setEditingTemplateId(template.id) }}
                              onMouseLeave={() => { !isTemplateModalOpen && setEditingTemplateId(null) }}
                            >
                              {template.name}
                              {editingTemplateId === template.id && (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setTemplateName(template.name)
                                    setSelectedGroupId(category.id)
                                    setIsTemplateModalOpen(true)
                                  }}
                                  className="absolute right-0 top-1/2 transform -translate-y-1/2 p-1 hover:bg-gray-200 rounded"
                                >
                                  <EditIcon />
                                </button>
                              )}
                            </div>
                          </th>
                        ))
                      )
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {students.map((student, index) => (
                  <tr key={student.id} className="hover:bg-gray-50 h-[60px]">
                    <StudentCell student={student} index={index} />
                    {categories.map(category =>
                      collapsedCategories[category.id]
                        ? (
                          <td key={category.id} className="px-2 py-2 border border-gray-200 bg-gray-100 w-[60px]"></td>
                        )
                        : (
                          category.grade_templates.map(template => {
                            const grade = findStudentGrade(student.id, template)
                            return (
                              <GradeCell
                                key={`${student.id}-${template.id}`}
                                student={student}
                                template={template}
                                grade={grade}
                                editingCell={editingCell}
                                onGradeClick={() => {
                                  setIsGradeEditOpen(false)
                                  setSelectedGrade(null)
                                  handleGradeClick(student, template, grade)
                                }}
                                onGradeChange={handleGradeChange}
                                onKeyPress={(e) => { void handleKeyPress(e, index, student.id) }}
                                onOpenGradeEdit={() => {
                                  if (grade) {
                                    setSelectedGrade({
                                      id: grade.id,
                                      studentId: student.id,
                                      gradeTemplateId: template.id,
                                      gradeType: grade.grade_type as 'present' | 'absent' | 'exempt',
                                      value: grade.value,
                                      note: grade.note
                                    })
                                    setIsGradeEditOpen(true)
                                  }
                                }}
                              />
                            )
                          })
                        )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeacherLessonGrades
