import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { API_ADMIN_CLASS_UNITS_PATH } from '../../../constants/apiRoutes'
import ClassUnitForm from '../../../components/admin/class_units/ClassUnitForm'

const NewCalendarEventView = (): ReactElement => {
  const { t } = useTranslation('translations')

  return (
    <div className="grid lg:w-1/2" >
      <div className="px-5 lg:px-14 py-8">
        <h1 className="text-2xl  lg:text-3xl text-left pb-12">{t('admin.class_units.new_header')}</h1>
        <ClassUnitForm url={API_ADMIN_CLASS_UNITS_PATH}/>
      </div>
    </div>
  )
}

export default NewCalendarEventView
