import React from 'react'
import {
  type TimeSlotEventsProps
} from '../../../constants/types'
import {
  isEventDuringSlot,
  getDayEvents,
  getTimeSlots,
  shouldBeHalfWidth,
  calculateTotalHeight,
  isToday,
  isLessonInstance,
  getEventSpanForWeek
} from '../utils'
import { LessonButton } from './LessonButton'
import { CalendarEventButton } from './CalendarEventButton'
import { useTranslation } from 'react-i18next'

export const TimeSlotEvents = ({
  calendarRef,
  isMobile,
  weekBoundaries,
  lessonInstances,
  lessonSlots,
  calendarEvents,
  homeworks,
  handleLessonClick,
  handleCalendarEventClick,
  handleHomeworkClick,
  getWeekdayLabel,
  toLocalHour,
  toLocalDateWithHour,
  activeLessonId,
  activeCalendarEventId,
  activeHomeworkId
}: TimeSlotEventsProps): JSX.Element => {
  const { t } = useTranslation('translations')
  const multiDayEvents = calendarEvents.filter(event => {
    const eventStart = new Date(event.starts_at)
    const eventEnd = new Date(event.ends_at)
    const diffTime = Math.abs(eventEnd.getTime() - eventStart.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays > 1
  })

  return (
    <div
      ref={calendarRef}
      className={isMobile ? 'overflow-x-auto relative' : ''}
    >
      <div
        className="grid grid-cols-6 border-t border-b border-gray-200"
        style={{
          gridTemplateColumns: isMobile ? '90px repeat(5, minmax(100px, 1fr))' : '120px repeat(5, minmax(100px, 1fr))',
          gridTemplateRows: '30px auto repeat(auto-fill, minmax(3rem, auto))',
          minWidth: isMobile ? '1350px' : 'auto'
        }}
      >
        <div className="border-r border-l sticky left-0 z-10 bg-white">
        </div>
        {Array.from({ length: 5 }).map((_, index) => {
          const date = new Date(weekBoundaries.weekStart)
          date.setDate(date.getDate() + index)
          return (
            <div key={index} className={`text-center py-2 font-semibold text-xs border-r flex items-center justify-center ${isToday(date) ? 'text-blue-700 font-bold' : 'text-gray-500'}`}>
              {getWeekdayLabel(date)}
            </div>
          )
        })}

        {multiDayEvents.length > 0 && (
          <>
            <div className="border-r border-t border-l sticky left-0 z-10 bg-white h-full" />
            <div className="col-span-5 relative">
              <div className="grid grid-cols-5 h-full absolute inset-0">
                {Array.from({ length: 5 }).map((_, index) => {
                  const date = new Date(weekBoundaries.weekStart)
                  date.setDate(date.getDate() + index)

                  return (
                    <div
                      key={`multi-day-column-${index}`}
                      className={`border-r border-t ${isToday(date) ? 'bg-zinc-100 border-t-blue-700' : 'bg-zinc-50'}`}
                    />
                  )
                })}
              </div>
              <div className="relative z-10 p-1 flex flex-col gap-1">
                {multiDayEvents
                  .filter(event => {
                    const eventStart = new Date(event.starts_at)
                    const eventEnd = new Date(event.ends_at)

                    eventStart.setHours(0, 0, 0, 0)
                    eventEnd.setHours(23, 59, 59, 999)

                    const weekStart = new Date(weekBoundaries.weekStart)
                    const weekEnd = new Date(weekBoundaries.weekStart)
                    weekEnd.setDate(weekEnd.getDate() + 4)

                    return eventStart <= weekEnd && eventEnd >= weekStart
                  })
                  .map((event) => {
                    const { startOffset, span } = getEventSpanForWeek(event, weekBoundaries.weekStart)
                    return (
                      <div
                        key={event.id}
                        className="relative"
                        style={{
                          left: `${(startOffset * 100) / 5 + 0.05}%`,
                          width: `${(span * 100) / 5 - 0.1}%`
                        }}
                      >
                        <CalendarEventButton
                          event={event}
                          onClick={handleCalendarEventClick}
                          isActive={event.id === activeCalendarEventId}
                        />
                      </div>
                    )
                  })}
              </div>
            </div>
          </>
        )}

        <div className="border-r border-l sticky left-0 z-10 bg-white">
        </div>
        {Array.from({ length: 5 }).map((_, index) => {
          const date = new Date(weekBoundaries.weekStart)
          date.setDate(date.getDate() + index)
          const dayCalendarEvents = (calendarEvents || []).filter(event => {
            const eventStart = new Date(event.starts_at)
            const eventEnd = new Date(event.ends_at)
            const diffTime = Math.abs(eventEnd.getTime() - eventStart.getTime())
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
            return eventStart.toDateString() === date.toDateString() &&
              diffDays === 1 &&
              (!lessonSlots?.some(slot => isEventDuringSlot(event, slot, date)))
          })
          const dayHomeworks = (homeworks || []).filter(event => {
            const eventDeadline = new Date(event.deadline)
            return eventDeadline.toDateString() === date.toDateString()
          })

          return (
            <div
              key={`outside-events-${index}`}
              className={`border-r p-0.5 relative ${isToday(date) ? 'bg-zinc-100' : 'bg-zinc-50'}`}
            >
              {dayHomeworks.map(event => (
                <button
                  key={event.id}
                  className={`px-2 py-2 text-xs font-extrabold truncate text-left inline-block border relative ${
                    event.id === activeHomeworkId ? 'border-blue-500' : 'border-gray-200'
                  } bg-red-100 w-full rounded shadow-sm`}
                  onClick={(e) => { handleHomeworkClick(event, e) }}
                >
                  <div className="w-full flex gap-1 truncate text-[11px]">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-4 w-4 shrink-0">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                    </svg>
                    {t('calendar.homework')}
                  </div>
                  <div className="w-full truncate text-gray-600 font-normal text-[9px]">
                    {event.lesson?.subject?.name}
                  </div>
                  <div className="w-full truncate font-normal text-[9px]">
                    {t('calendar.deadline')}: {toLocalDateWithHour(event.deadline)}
                  </div>
                  {event.homework_solutions && (
                    <div className="absolute bottom-1 right-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="green" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                      </svg>
                    </div>
                  )}
                </button>
              ))}
              {dayCalendarEvents.map(event => (
                <button
                  key={event.id}
                  className={`px-2 py-2 text-xs font-extrabold truncate text-left inline-block border ${
                    event.id === activeCalendarEventId ? 'border-blue-500' : 'border-gray-200'
                  } ${
                    {
                      normal: 'bg-yellow-100',
                      holiday: 'bg-green-100',
                      default: 'bg-gray-100'
                    }[event.event_type ?? 'default']
                  } w-full rounded shadow-sm`}
                  onClick={(e) => { handleCalendarEventClick(event, e) }}
                >
                  <div className="w-full truncate text-[11px]">
                    {event.event_type === 'normal' ? event.title : t('calendar.holiday')}
                  </div>
                  {event.event_type === 'normal' && (
                    <div className="w-full truncate text-gray-600 font-normal text-[9px]">
                      {event.description}
                    </div>
                  )}
                  <div className="w-full truncate font-normal text-[9px]">
                    {event.all_day
                      ? (
                        <span className="bg-gray-200 text-gray-600 px-1.5 py-0.5 rounded text-[9px]">
                          {t('calendar.all_day')}
                        </span>
                      )
                      : (
                        `${toLocalHour(event.starts_at)} - ${toLocalHour(event.ends_at)}`
                      )
                    }
                  </div>
                </button>
              ))}
            </div>
          )
        })}

        {getTimeSlots(lessonSlots).map((time: string, slotIndex: number) => {
          const [startTime, endsAt] = time.split('-')
          return (
            <React.Fragment key={`time-${time}`}>
              <div className="text-center pt-2 text-xs text-gray-500 bg-white flex justify-center border-r border-t border-l sticky left-0 z-30">
                {time}
              </div>
              {Array.from({ length: 5 }).map((_, index) => {
                const date = new Date(weekBoundaries.weekStart)
                date.setDate(weekBoundaries.weekStart.getDate() + index)
                const dayEvents = getDayEvents(date, lessonInstances, calendarEvents.filter(event => {
                  const eventStart = new Date(event.starts_at)
                  const eventEnd = new Date(event.ends_at)
                  const diffTime = Math.abs(eventEnd.getTime() - eventStart.getTime())
                  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                  return diffDays === 1
                }))
                const timeSlots = getTimeSlots(lessonSlots)

                // Get calendar events that overlap with this time slot
                const slotCalendarEvents = (calendarEvents || []).filter(event => {
                  const eventStart = new Date(event.starts_at)
                  const eventStartDate = eventStart.toDateString()
                  const currentDate = date.toDateString()
                  return eventStartDate === currentDate && isEventDuringSlot(event, { starts_at: startTime, ends_at: endsAt }, date)
                })

                // Calculate spans for calendar events
                const eventSpans = slotCalendarEvents.map(event => {
                  const eventEnd = new Date(event.ends_at)
                  const eventStart = new Date(event.starts_at)

                  // Find the starting slot index
                  const eventStartSlotIndex = timeSlots.findIndex(slot => {
                    const [slotStart] = slot.split('-')
                    const [startHours, startMinutes] = slotStart.split(':').map(Number)

                    // Create slot time boundaries in local time
                    const slotStartTime = new Date(date)
                    slotStartTime.setHours(startHours, startMinutes, 0, 0)

                    const nextSlot = timeSlots[timeSlots.indexOf(slot) + 1]
                    let slotEndTime
                    if (nextSlot) {
                      const [nextStart] = nextSlot.split('-')
                      const [nextHours, nextMinutes] = nextStart.split(':').map(Number)
                      slotEndTime = new Date(date)
                      slotEndTime.setHours(nextHours, nextMinutes, 0, 0)
                    } else {
                      slotEndTime = new Date(date)
                      slotEndTime.setHours(23, 59, 59, 999)
                    }

                    return eventStart >= slotStartTime && eventStart < slotEndTime
                  })

                  // Only process if this is the starting slot
                  if (slotIndex === eventStartSlotIndex) {
                    let spanCount = 1

                    // Start checking from the next slot
                    for (let i = eventStartSlotIndex + 1; i < timeSlots.length; i++) {
                      const [slotStart] = timeSlots[i].split('-')
                      const [startHours, startMinutes] = slotStart.split(':').map(Number)

                      const slotStartTime = new Date(date)
                      slotStartTime.setHours(startHours, startMinutes, 0, 0)

                      // If event ends after this slot starts, it spans into this slot
                      if (eventEnd > slotStartTime) {
                        spanCount++
                      } else {
                        break
                      }
                    }

                    return { event, spanCount, shouldRender: true }
                  }

                  return { event, spanCount: 1, shouldRender: false }
                }).filter(({ shouldRender }) => shouldRender)

                return (
                  <div
                    key={`event-${index}-${time}`}
                    data-slot-index={slotIndex}
                    className={`border-r border-t min-h-[3rem] relative p-0.5 ${isToday(date) ? 'bg-zinc-100' : 'bg-zinc-50'}`}
                  >
                    <div className="flex flex-col items-start gap-1 h-full relative">
                      {dayEvents
                        .filter(event => {
                          if (!isLessonInstance(event)) return false
                          return isEventDuringSlot(event, { starts_at: startTime, ends_at: endsAt }, date)
                        })
                        .map(event => (
                          <div key={event.id} className="w-full relative">
                            {
                              'subject' in event
                                ? <LessonButton event={event} onClick={handleLessonClick} isActive={event.id === activeLessonId} />
                                : <CalendarEventButton event={event} onClick={handleCalendarEventClick} isActive={event.id === activeCalendarEventId} />
                            }
                          </div>
                        ))}

                      {eventSpans.map(({ event, spanCount }) => {
                        const className = `absolute ${shouldBeHalfWidth(
                          slotIndex,
                          spanCount,
                          dayEvents,
                          timeSlots,
                          date
                        )
                          ? 'right-0'
                          : 'left-0 w-full'
                        }`

                        return (
                          <div
                            key={event.id}
                            className={className}
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: shouldBeHalfWidth(
                                slotIndex,
                                spanCount,
                                dayEvents,
                                timeSlots,
                                date
                              )
                                ? '50%'
                                : '',
                              height: calculateTotalHeight(slotIndex, spanCount),
                              zIndex: spanCount > 1 ? 20 : 10,
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <CalendarEventButton
                              event={event}
                              onClick={handleCalendarEventClick}
                              isActive={event.id === activeCalendarEventId}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
