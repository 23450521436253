import React, { type ReactElement, useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ActiveTabFilter from '../../filters/tabs/ActiveTabFilter'
import InactiveTabFilter from '../../filters/tabs/InactiveTabFilter'
import CommentIcon from '../../teachers/grades/icons/CommentIcon'
import type { FlattenedGrade, StudentLessonGradesProps, TeacherData } from '../../../constants/types'
import DefaultAvatar from '../../../assets/default_avatar.svg'
import { Link } from 'react-router-dom'
import { USER_PROFILE_PATH } from '../../../constants/routes'
import { useParseDate } from '../../../hooks/useParseDate'

const StudentLessonGrades = ({ grades = [], lesson }: StudentLessonGradesProps): ReactElement => {
  const { t } = useTranslation('translations')
  const [activeView, setActiveView] = useState<'alphabetical' | 'chronological'>('alphabetical')
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false)
  const [selectedGrade, setSelectedGrade] = useState<{
    note: string | null
    value: string
    template_name: string
    group_name: string
    created_at: Date
    graded_by: TeacherData
  } | null>(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const { toLocalDateWithHour } = useParseDate()

  useEffect(() => {
    const handleResize = (): void => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  }, [])

  const TeachersList = ({ teachers, t }: { teachers: TeacherData[], t: (key: string) => string }): ReactElement => {
    return (
      <div className="flex flex-row space-x-4 lg:mx-4">
        <div className="flex flex-col items-end w-24">
          <div className="font-['DM Sans'] text-zinc-700">
            {t('lessons.heading.teacher')}:
          </div>
        </div>
        <div className="flex flex-col items-start flex-1">
          <div className="flex flex-col gap-2">
            {teachers?.map((teacher) => (
              <Link
                key={teacher.id}
                to={USER_PROFILE_PATH.replace(':id', teacher.user?.id.toString())}
                className="flex gap-3 lg:items-center items-start font-['DM Sans']"
              >
                <img
                  src={teacher.user?.avatar.url ?? DefaultAvatar}
                  className="object-cover cursor-pointer h-7 w-7 rounded-full flex-shrink-0 mt-0.5"
                  alt="Avatar"
                />
                <span className="lg:pt-0 pt-1">{teacher.user?.name}</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    )
  }

  const uniqueGrades = useMemo(() =>
    Array.from(new Map(grades.map(item => [item.id, item])).values())
  , [grades])

  const flattenedGrades = useMemo(() => {
    return uniqueGrades.flatMap(group =>
      group.grade_templates.flatMap(template =>
        template.grades.map(grade => ({
          ...grade,
          template_name: template.name,
          group_name: group.name
        } as FlattenedGrade))
      )
    )
  }, [uniqueGrades])

  const totalGradesCount = useMemo(() =>
    uniqueGrades.reduce((sum, group) =>
      sum + group.grade_templates.reduce((templateSum, template) =>
        templateSum + template.grades.length, 0
      ), 0
    ), [uniqueGrades]
  )

  const renderAlphabeticalView = (): ReactElement => {
    if (!uniqueGrades.length) return <></>

    const sortedGroups = [...uniqueGrades].sort((a, b) => a.name.localeCompare(b.name))
    let isFirstRow = true

    return (
      <>
        {sortedGroups.map(group => {
          const groupGradesCount = group.grade_templates.reduce(
            (sum, template) => sum + template.grades.length,
            0
          )

          let isFirstInGroup = true

          return group.grade_templates.map(template =>
            template.grades.map(grade => {
              const currentIsFirstRow = isFirstRow
              const currentIsFirstInGroup = isFirstInGroup
              isFirstRow = false
              isFirstInGroup = false

              return (
                <tr key={grade.id}>
                  {currentIsFirstRow && (
                    <td
                      rowSpan={totalGradesCount}
                      className="sticky left-0 z-10 px-4 py-3 text-sm text-gray-900 border-r border-b border-gray-200 bg-white after:absolute after:top-0 after:right-[-8px] after:content-[''] after:w-[7px] after:h-full after:bg-gradient-to-r after:from-black/[.07] after:to-transparent"
                    >
                      {lesson?.subject?.name}
                    </td>
                  )}
                  {currentIsFirstInGroup && (
                    <td
                      rowSpan={groupGradesCount}
                      className="px-4 py-3 text-sm text-gray-900 border-r border-b border-gray-200"
                    >
                      {group.name}
                    </td>
                  )}
                  <td className="px-4 py-3 text-sm text-gray-900 border-b border-gray-200">
                    <div className="flex items-center gap-2">
                      <span>
                        {grade.value}
                        <span className="text-xs text-gray-500 pl-3">
                          ({template.name}) {toLocalDateWithHour(grade.created_at)}
                        </span>
                      </span>
                      {grade.note && (
                        <div
                          onClick={() => {
                            setSelectedGrade({
                              note: grade.note,
                              value: grade.value,
                              template_name: template.name,
                              group_name: group.name,
                              created_at: new Date(grade.created_at),
                              graded_by: grade.graded_by
                            })
                            setIsCommentModalOpen(true)
                          }}
                          className="cursor-pointer"
                        >
                          <CommentIcon />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              )
            })
          )
        })}
      </>
    )
  }

  const renderChronologicalView = (): ReactElement => {
    if (isMobile) {
      return (
        <div className="bg-white rounded-lg shadow">
          {/* Fixed header */}
          <div className="flex border-b border-t border-gray-200 bg-gray-50">
            <div className="w-32 flex-shrink-0 p-3 border-r border-gray-200 bg-gray-50 sticky left-0 z-10 relative after:absolute after:top-0 after:right-[-8px] after:content-[''] after:w-[7px] after:h-full after:bg-gradient-to-r after:from-black/[.07] after:to-transparent">
              <span className="text-sm font-medium text-gray-500">{t('grades.subject')}</span>
            </div>
            <div className="p-3">
              <span className="text-sm font-medium text-gray-500">{t('grades.grades_uppercase')}</span>
            </div>
          </div>
          {/* Content rows */}
          <div className="divide-y divide-gray-200">
            {flattenedGrades.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).map(grade => (
              <div key={grade.id} className="flex hover:bg-gray-50">
                <div className="w-32 flex-shrink-0 p-3 border-r border-gray-200 bg-white sticky left-0 z-10 relative after:absolute after:top-0 after:right-[-8px] after:content-[''] after:w-[7px] after:h-full after:bg-gradient-to-r after:from-black/[.07] after:to-transparent">
                  <div className="text-sm truncate">
                    {lesson?.subject?.name}
                  </div>
                </div>
                <div className="p-3 flex-1">
                  <div className="text-sm font-medium mb-1">{grade.group_name}</div>
                  <div className="flex items-center gap-2">
                    <span>{grade.value} ({grade.template_name}) {toLocalDateWithHour(grade.created_at)}</span>
                    {grade.note && (
                      <button
                        onClick={() => {
                          setSelectedGrade({
                            note: grade.note,
                            value: grade.value,
                            template_name: grade.template_name,
                            group_name: grade.group_name,
                            created_at: new Date(grade.created_at),
                            graded_by: grade.graded_by
                          })
                          setIsCommentModalOpen(true)
                        }}
                        className="text-gray-500"
                      >
                        <CommentIcon />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    }

    return (
      <>
        {flattenedGrades.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).map(grade => (
          <tr key={grade.id} className="hover:bg-gray-50">
            <td className="sticky left-0 z-10 px-4 py-3 text-sm text-gray-900 border-r border-b border-gray-200 bg-white after:absolute after:top-0 after:right-[-8px] after:content-[''] after:w-[7px] after:h-full after:bg-gradient-to-r after:from-black/[.07] after:to-transparent">
              {lesson?.subject?.name}
            </td>
            <td className="px-4 py-3 text-sm text-gray-900 border-r border-b border-gray-200">
              {grade.group_name}
            </td>
            <td className="px-4 py-3 text-sm text-gray-900 border-b border-gray-200">
              <div className="flex items-center gap-2">
                <span>{grade.value} ({grade.template_name}) {toLocalDateWithHour(grade.created_at)}</span>
                {grade.note && (
                  <div
                    onClick={() => {
                      setSelectedGrade({
                        note: grade.note,
                        value: grade.value,
                        template_name: grade.template_name,
                        group_name: grade.group_name,
                        created_at: new Date(grade.created_at),
                        graded_by: grade.graded_by
                      })
                      setIsCommentModalOpen(true)
                    }}
                    className="cursor-pointer"
                  >
                    <CommentIcon />
                  </div>
                )}
              </div>
            </td>
          </tr>
        ))}
      </>
    )
  }

  return (
    <>
      {lesson?.subject?.name && (
        <>
          <div className="text-left text-[32px] font-bold lg:block hidden mb-6">
            {lesson?.subject?.name}
          </div>
          <div className="lg:py-[30px] py-[20px] px-5">
            {lesson?.teachers && <TeachersList teachers={lesson.teachers} t={t} />}
          </div>
        </>
      )}

      <div className="flex gap-4 mb-6 lg:justify-start justify-center">
        <div className="inline-flex p-1 bg:white shadow-lg rounded-lg">
          {activeView === 'alphabetical'
            ? (
              <ActiveTabFilter
                text={t('grades.by_groups')}
                setFilters={() => { setActiveView('alphabetical') }}
              />
            )
            : (
              <InactiveTabFilter
                text={t('grades.by_groups')}
                setFilters={() => { setActiveView('alphabetical') }}
              />
            )
          }
          {activeView === 'chronological'
            ? (
              <ActiveTabFilter
                text={t('grades.by_date')}
                setFilters={() => { setActiveView('chronological') }}
              />
            )
            : (
              <InactiveTabFilter
                text={t('grades.by_date')}
                setFilters={() => { setActiveView('chronological') }}
              />
            )
          }
        </div>
      </div>

      {isMobile
        ? (
          activeView === 'alphabetical'
            ? (
              <div className="bg-white rounded-lg shadow">
                {/* Fixed header */}
                <div className="flex border-b border-t border-gray-200 bg-gray-50">
                  <div className="w-32 flex-shrink-0 p-3 border-r border-gray-200 bg-gray-50 sticky left-0 z-10 relative after:absolute after:top-0 after:right-[-8px] after:content-[''] after:w-[7px] after:h-full after:bg-gradient-to-r after:from-black/[.07] after:to-transparent">
                    <span className="text-sm font-medium text-gray-500">{t('grades.subject')}</span>
                  </div>
                  <div className="p-3">
                    <span className="text-sm font-medium text-gray-500">{t('grades.grades_uppercase')}</span>
                  </div>
                </div>

                {/* Content row */}
                <div className="flex">
                  {/* Fixed subject column */}
                  <div className="w-32 flex-shrink-0 p-3 border-r border-gray-200 bg-white sticky left-0 z-10 relative after:absolute after:top-0 after:right-[-8px] after:content-[''] after:w-[7px] after:h-full after:bg-gradient-to-r after:from-black/[.07] after:to-transparent">
                    <div className="text-sm truncate">
                      {lesson?.subject?.name}
                    </div>
                  </div>

                  {/* Scrollable grades container */}
                  <div className="overflow-x-auto" style={{ WebkitOverflowScrolling: 'touch' }}>
                    <div className="min-w-[500px]"> {/* Force minimum width to enable scrolling */}
                      {uniqueGrades.map((group) => (
                        <div key={group.id} className="mb-6 last:mb-0 p-3">
                          <div className="font-medium text-sm mb-3 pb-2 border-b border-gray-100">{group.name}</div>
                          <div className="grid grid-cols-1 gap-3">
                            {group.grade_templates.flatMap(template =>
                              template.grades.map(grade => (
                                <div key={grade.id} className="flex items-center gap-2">
                                  <span className="whitespace-nowrap">
                                    {grade.value}
                                    <span className="text-xs text-gray-500 ml-1">
                                      ({template.name}) {toLocalDateWithHour(grade.created_at)}
                                    </span>
                                  </span>
                                  {grade.note && (
                                    <button
                                      onClick={() => {
                                        setSelectedGrade({
                                          note: grade.note,
                                          value: grade.value,
                                          template_name: template.name,
                                          group_name: group.name,
                                          created_at: new Date(grade.created_at),
                                          graded_by: grade.graded_by
                                        })
                                        setIsCommentModalOpen(true)
                                      }}
                                      className="text-gray-500"
                                    >
                                      <CommentIcon />
                                    </button>
                                  )}
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )
            : (
              renderChronologicalView()
            )
        )
        : (
          <div className="overflow-hidden rounded-lg shadow border-t border-l border-r">
            <table className="w-full bg-white">
              <thead>
                <tr className="bg-gray-50">
                  <th className="sticky left-0 z-10 px-4 py-3 text-left text-sm text-gray-500 font-medium border-b border-r border-gray-200 bg-gray-50 after:absolute after:top-0 after:right-[-8px] after:content-[''] after:w-[7px] after:h-full after:bg-gradient-to-r after:from-black/[.07] after:to-transparent">
                    {t('grades.subject')}
                  </th>
                  <th className="px-4 py-3 text-left text-sm text-gray-500 font-medium border-b border-r border-gray-200">
                    {t('grades.group')}
                  </th>
                  <th className="px-4 py-3 text-left text-sm text-gray-500 font-medium border-b border-gray-200">
                    {t('grades.grade_uppercase')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {activeView === 'alphabetical' ? renderAlphabeticalView() : renderChronologicalView()}
              </tbody>
            </table>
          </div>
        )
      }

      {isCommentModalOpen && selectedGrade && (
        <>
          {isMobile
            ? (
              <>
                <div
                  className="fixed inset-0 z-40 bg-black bg-opacity-50"
                  onClick={() => {
                    setIsCommentModalOpen(false)
                    setSelectedGrade(null)
                  }}
                />
                <div className="fixed inset-x-0 bottom-0 z-50 mb-[80px]">
                  <div
                    className="bg-white shadow-lg w-full mx-auto rounded-t-xl"
                    onClick={(e) => { e.stopPropagation() }}
                  >
                    <div className="p-5">
                      <div className="flex items-center justify-between pb-4 border-b border-gray-100">
                        <div className="flex items-center gap-3">
                          <img
                            src={selectedGrade.graded_by?.user?.avatar.url ?? DefaultAvatar}
                            className="object-cover h-10 w-10 rounded-full flex-shrink-0"
                            alt="Teacher avatar"
                          />
                          <span className="text-base font-medium text-gray-900">
                            {selectedGrade.graded_by?.user?.name}
                          </span>
                        </div>
                        <button
                          onClick={() => {
                            setIsCommentModalOpen(false)
                            setSelectedGrade(null)
                          }}
                          className="text-gray-500 hover:text-gray-700"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>

                      <div className="mt-4 space-y-4">
                        <div>
                          <h2 className="text-base font-bold">{lesson?.subject?.name}</h2>
                          <p className="text-sm font-medium mt-2">{selectedGrade.group_name}</p>
                        </div>

                        <div>
                          <div className="flex items-center gap-2">
                            <div className="text-xl font-bold">{selectedGrade.value}</div>
                            <div className="text-sm text-gray-500">({selectedGrade.template_name})</div>
                          </div>
                          <p className="text-xs text-gray-500 mt-1">
                            {t('grades.created_at')}: {toLocalDateWithHour(selectedGrade.created_at)}
                          </p>
                        </div>

                        {selectedGrade.note && (
                          <div className="mt-4">
                            <h3 className="text-sm font-bold mb-2">{t('grades.comment')}</h3>
                            <p className="text-sm text-gray-700">
                              {selectedGrade.note}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
            : (
              <div className="fixed inset-0 overflow-hidden z-50">
                <div className="absolute inset-0 overflow-hidden">
                  <div
                    className="absolute inset-0"
                    onClick={() => {
                      setIsCommentModalOpen(false)
                      setSelectedGrade(null)
                    }}
                  />
                  <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                    <div className="relative w-[600px]">
                      <div className="h-full flex flex-col bg-white shadow-xl border border-l-gray-400" onClick={(e) => { e.stopPropagation() }}>
                        <div className="p-4 flex justify-end">
                          <button
                            type="button"
                            className="rounded-md text-gray-400 hover:text-gray-500"
                            onClick={() => { setIsCommentModalOpen(false) }}
                          >
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>

                        <div className="flex-1 flex justify-center items-center">
                          <div className="w-full px-6">
                            <div className="space-y-6">
                              <div className="flex items-center gap-3 pb-2">
                                <div className="relative group">
                                  <img
                                    src={selectedGrade.graded_by?.user?.avatar.url ?? DefaultAvatar}
                                    className="object-cover h-10 w-10 rounded-full flex-shrink-0"
                                    alt="Teacher avatar"
                                  />
                                  <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block bg-white p-1 rounded-lg shadow-lg z-20 w-[100px] h-[100px]">
                                    <img
                                      src={selectedGrade.graded_by?.user?.avatar.url ?? DefaultAvatar}
                                      className="w-full h-full object-cover rounded-lg"
                                      alt="Teacher avatar"
                                    />
                                  </div>
                                </div>
                                <span className="text-base font-medium text-gray-900">
                                  {selectedGrade.graded_by?.user?.name}
                                </span>
                              </div>
                              <div>
                                <h2 className="text-xl font-medium">{lesson?.subject?.name}</h2>
                                <p className="text-base font-extrabold mt-5">{selectedGrade.group_name}</p>
                                <p className="text-base font-medium mt-1">{selectedGrade.value} ({selectedGrade.template_name})</p>
                                <p className="text-sm font-medium mt-1 text-gray-700">
                                  {t('grades.created_at')}: {toLocalDateWithHour(selectedGrade.created_at)}
                                </p>
                              </div>
                              <div>
                                <h3 className="text-base font-extrabold mb-2">{t('grades.comment')}</h3>
                                <p className="text-sm text-gray-600">{selectedGrade.note}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </>
      )}
    </>
  )
}

export default StudentLessonGrades
