import React, { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { API_STUDENT_LESSON_GRADES_PATH, API_STUDENT_LESSON_PATH } from '../../constants/apiRoutes'
import StudentLessonGrades from '../../components/students/grades/StudentLessonGrades'

const StudentLessonGradesView = (): ReactElement => {
  const queryParams = useParams()
  const lessonId = queryParams.id ?? 'defaultLessonId'

  const { data: gradesData } = useApiFetch({
    url: API_STUDENT_LESSON_GRADES_PATH.replace(':id', lessonId),
    enabled: !!lessonId
  })

  const { data: lessonData } = useApiFetch({
    url: API_STUDENT_LESSON_PATH.replace(':id', lessonId),
    enabled: !!lessonId
  })

  return (
    <div className="grid h-screen">
      <div className="lg:px-12 pb-20 pt-2 card lg:mx-1 w-screen">
        <StudentLessonGrades
          grades={gradesData}
          lesson={lessonData}
        />
      </div>
    </div>
  )
}

export default StudentLessonGradesView
