import React, { type ReactElement } from 'react'
import CalendarComponent from '../../components/calendar/CalendarComponent'
import { useTranslation } from 'react-i18next'

const StudentDashboardView = (): ReactElement => {
  const { t } = useTranslation('translations')

  return (
    <div className="flex justify-center lg:m-10 lg:mb-16 mb-32">
      <div className="space-y-4 w-full">
        <div className="text-2xl font-bold lg:m-0 m-5 leading-tight">
          {t('school_hall.h1')}
        </div>
        <CalendarComponent />
      </div>
    </div>
  )
}

export default StudentDashboardView
