import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import ClassUnitForm from '../../../components/admin/class_units/ClassUnitForm'
import { API_ADMIN_EDIT_CLASS_UNIT_PATH } from '../../../constants/apiRoutes'

const EditClassUnitEventView = (): ReactElement => {
  const queryParams = useParams()
  const apiUrl = API_ADMIN_EDIT_CLASS_UNIT_PATH.replace(':id', queryParams.id as string)
  const { data: classUnit } = useApiFetch({ url: apiUrl })
  const { t } = useTranslation('translations')

  return (
    <div className="grid lg:w-1/2" >
      <div className="px-5 lg:px-14 py-8">
        <h1 className="text-2xl lg:text-3xl text-left pb-12">{t('admin.class_units.edit_header')}</h1>
        <ClassUnitForm url={apiUrl} classUnit={classUnit} method={'patch'} />
      </div>
    </div>
  )
}

export default EditClassUnitEventView
