import React, { type ReactElement } from 'react'
import DefaultAvatar from '../../../../assets/default_avatar.svg'
import type { GradeEditModalProps } from '../../../../constants/types'

const GradeEditModal: React.FC<GradeEditModalProps> = ({
  isOpen,
  onClose,
  isMobile,
  selectedGrade,
  onGradeChange,
  onSave,
  students,
  t
}): ReactElement | null => {
  if (!isOpen || !selectedGrade) return null

  const student = students.find(s => s.id === selectedGrade.studentId)

  const renderMobileContent = (): ReactElement => (
    <>
      <div
        className="fixed inset-0 z-40"
        onClick={() => {
          onClose()
        }}
      />
      <div className="fixed inset-x-0 bottom-0 z-50 mb-[80px]">
        <div
          className="bg-white shadow-lg w-full mx-auto rounded-xl border border-gray-300"
          onClick={(e) => { e.stopPropagation() }}
        >
          <div className="p-4">
            <div className="flex items-center gap-3 pb-4">
              <div className="relative group">
                <img
                  src={student?.user.avatar?.url ?? DefaultAvatar}
                  className="object-cover h-10 w-10 rounded-full flex-shrink-0"
                  alt="Student avatar"
                />
              </div>
              <span className="text-base font-medium text-gray-900">
                {student?.user.name}
              </span>
              <button
                onClick={() => {
                  onClose()
                }}
                className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {renderFormContent()}
          </div>
        </div>
      </div>
    </>
  )

  const renderDesktopContent = (): ReactElement => (
    <div className="fixed inset-0 overflow-hidden z-50">
      <div className="absolute inset-0 overflow-hidden">
        <div
          className="absolute inset-0"
          onClick={() => {
            onClose()
          }}
        />
        <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <div className="relative w-[600px]">
            <div className="h-full flex flex-col bg-white shadow-xl border border-l-gray-400" onClick={(e) => { e.stopPropagation() }}>
              <div className="p-4 flex justify-end">
                <button
                  type="button"
                  className="rounded-md text-gray-400 hover:text-gray-500"
                  onClick={() => {
                    onClose()
                  }}
                >
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div className="flex-1 flex justify-center items-center">
                <div className="w-full px-6">
                  {renderFormContent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const renderFormContent = (): ReactElement => (
    <div className="space-y-6">
      <div className="space-y-2">
        {['present', 'absent', 'exempt', 'none'].map((type) => (
          <div key={type} className="flex items-center">
            <input
              type="radio"
              id={type}
              name="gradeType"
              value={type}
              checked={selectedGrade.gradeType === type}
              onChange={(e) => {
                onGradeChange({ ...selectedGrade, gradeType: e.target.value })
              }}
              className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
            />
            <label htmlFor={type} className="ml-3 text-sm text-gray-700 capitalize">
              {t(`grades.types.${type}`)}
            </label>
          </div>
        ))}
      </div>

      {selectedGrade.gradeType === 'present' && (
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">{t('grades.grade')}</label>
            <input
              type="text"
              value={selectedGrade.value ?? ''}
              onChange={(e) => {
                onGradeChange({ ...selectedGrade, value: e.target.value })
              }}
              className="mt-2 px-4 py-2 block w-full border border-gray-200 rounded-xl shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">{t('grades.comment')}</label>
            <textarea
              rows={6}
              value={selectedGrade.note ?? ''}
              onChange={(e) => {
                onGradeChange({ ...selectedGrade, note: e.target.value })
              }}
              className="mt-2 px-4 py-2 block w-full border border-gray-200 rounded-xl shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>
      )}

      <div className="flex justify-end gap-3">
        <button
          type="button"
          className="text-blue-800 hover:text-white flex gap-2 justify-center py-2 px-4 border border-blue-800 rounded-lg hover:bg-blue-800 items-center"
          onClick={() => {
            onClose()
          }}
        >
          {t('grades.cancel')}
        </button>
        <button
          type="button"
          className="text-blue-800 hover:text-white flex gap-2 justify-center py-2 px-4 border border-blue-800 rounded-lg hover:bg-blue-800 items-center"
          onClick={() => {
            onSave()
          }}
        >
          {t('grades.save')}
        </button>
      </div>
    </div>
  )

  return isMobile ? renderMobileContent() : renderDesktopContent()
}

export default GradeEditModal
