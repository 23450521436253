import React, { type FormEvent, type ReactElement, useEffect } from 'react'
import { map } from 'underscore'
import { useTranslation } from 'react-i18next'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { ADMIN_CLASS_UNITS_PATH } from '../../../constants/adminRoutes'
import {
  API_ADMIN_CLASS_UNIT_COLORS_PATH,
  API_ADMIN_SCHOOL_YEARS_PATH,
  API_ADMIN_STUDENTS_PATH,
  API_ADMIN_TEACHERS_PATH
} from '../../../constants/apiRoutes'
import { type ClassUnitData, type StudentData, type TeacherData } from '../../../constants/types'
import Select from '../../common/Select'
import MultiSelect from '../../common/MultiSelect'

const ClassUnitForm = (
  { classUnit, url, method = 'post' }: { url: string, classUnit?: ClassUnitData, method?: string }
): ReactElement => {
  const { t } = useTranslation('translations')

  const { setInput, sendData, validationErrors, input } = useApiPost({ url, redirect: ADMIN_CLASS_UNITS_PATH, successFlashMessage: 'Pomyślnie zapisano' })
  const { data: colors } = useApiFetch({ url: API_ADMIN_CLASS_UNIT_COLORS_PATH, paginated: true })
  const { data: schoolYears } = useApiFetch({ url: API_ADMIN_SCHOOL_YEARS_PATH })
  const { data: students } = useApiFetch({ url: API_ADMIN_STUDENTS_PATH })
  const { data: teachers } = useApiFetch({ url: API_ADMIN_TEACHERS_PATH, defaultQuery: { status: 'active', paginated: 'false' } })

  useEffect(() => {
    if (classUnit) {
      setInput((_p: any) => {
        return {
          name: classUnit.name,
          color: classUnit.color,
          school_year_id: classUnit.school_year?.id?.toString(),
          student_ids: map(classUnit.students ?? [], (student: StudentData) => student.id.toString()),
          tutor_ids: map(classUnit.tutors ?? [], (teacher: TeacherData) => teacher.id.toString()),
          ...(classUnit.grade && { grade: classUnit.grade })
        }
      })
    }
  }, [classUnit])

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()

    const parsedData = {
      ...input,
      student_ids: map(input.student_ids, (studentId: string) => parseInt(studentId)),
      tutor_ids: map(input.tutor_ids, (tutorId: string) => parseInt(tutorId)),
      school_year_id: parseInt(input.school_year_id)
    }

    await sendData({ method, additionalParams: parsedData })
  }

  return (
    <form method={method} onSubmit={handleSubmit}>
      <label className='text-lg font-medium ml-1'>{t('admin.class_units.attributes.name')}</label>
      <br />
      <input
        value={input.name}
        className='px-2 py-1 border border-gray-300 rounded-lg mt-3 mb-3 py-2 resize-none w-full lg:w-2/3'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, name: e.target.value })) }}
      />
      <div className='text-sm text-red-500'>
        {map(validationErrors.name, (error: string): string => `${t('admin.class_units.attributes.name')} ${error}`).join(', ')}
      </div>
      <br />

      <label className='text-lg font-medium ml-1'>{t('admin.class_units.attributes.grade')}</label>
      <br />
      <input
        value={input.grade}
        className='px-2 py-1 border border-gray-300 rounded-lg mt-3 mb-3 py-2 resize-none w-full lg:w-2/3'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, grade: e.target.value })) }}
      />
      <div className='text-sm text-red-500'>
        {map(validationErrors.grade, (error: string): string => `${t('admin.class_units.attributes.grade')} ${error}`).join(', ')}
      </div>

      <br />
      <label className='text-lg font-medium ml-1'>{t('admin.class_units.attributes.color')}</label>
      <br />
      <div className='relative w-full lg:w-2/3'>
        <Select
          id="class-unit-color-select"
          placeholder={t('admin.class_units.color_placeholder')}
          value={input.color}
          options={map(colors, (color) => ({ text: color, value: color }))}
          optionIcon={(color) => (<div className={`h-5 w-5 bg-${color}`} />) }
          onChange={(selectedValue: string) => {
            setInput((prevInput: any) => ({
              ...prevInput,
              color: selectedValue
            }))
          }}
        />
      </div>
      <div className='text-sm text-red-500'>
        {map(validationErrors.color, (error: string): string => `${t('admin.class_units.attributes.color')} ${error}`).join(', ')}
      </div>
      <br />

      <br />
      <label className='text-lg font-medium ml-1'>{t('admin.class_units.attributes.school_year')}</label>
      <br />
      <div className='relative w-full lg:w-2/3'>
        <Select
          id="class-unit-school-year-select"
          placeholder={t('admin.class_units.school_year_placeholder')}
          value={input.school_year_id}
          options={map(schoolYears, (schoolYear) => ({ text: schoolYear.year, value: schoolYear.id }))}
          onChange={(selectedValue: string) => {
            setInput((prevInput: any) => ({
              ...prevInput,
              school_year_id: selectedValue
            }))
          }}
        />
      </div>
      <div className='text-sm text-red-500'>
        {map(validationErrors.school_year_id, (error: string): string => `${t('admin.class_units.attributes.school_year')} ${error}`).join(', ')}
      </div>
      <br />

      <div className="space-y-2">
        <label className='text-lg font-medium ml-1'>{t('admin.class_units.attributes.students')}</label>
        <MultiSelect
          id="class-unit-students-select"
          className="w-full lg:w-2/3"
          dropdownClasses="w-full"
          placeholder={t('admin.class_units.students_placeholder')}
          defaultSelected={input.student_ids}
          options={map(students, (student: StudentData) => ({ text: student.user?.name, value: student.id.toString() }))}
          onChange={(selectedValues: string) => {
            const selectedStudentIds = selectedValues.split(',')
            setInput((prevInput: any) => ({
              ...prevInput,
              student_ids: map(selectedStudentIds, (id) => parseInt(id))
            }))
          }}
        />
        <div className='text-sm text-red-500'>
          {map(validationErrors.student_ids, (error: string): string => `${t('admin.class_units.attributes.students')} ${error}`).join(', ')}
        </div>
      </div>

      <div className="space-y-2">
        <label className='text-lg font-medium ml-1'>{t('admin.class_units.attributes.tutors')}</label>
        <MultiSelect
          id="class-unit-tutors-select"
          className="w-full lg:w-2/3"
          dropdownClasses="w-full"
          placeholder={t('admin.class_units.tutors_placeholder')}
          defaultSelected={input.tutor_ids}
          options={map(teachers, (teacher: TeacherData) => ({ text: teacher.user?.name, value: teacher.id.toString() }))}
          onChange={(selectedValues: string) => {
            const selectedTutorIds = selectedValues.split(',')
            setInput((prevInput: any) => ({
              ...prevInput,
              tutor_ids: map(selectedTutorIds, (id) => parseInt(id))
            }))
          }}
        />
        <div className='text-sm text-red-500'>
          {map(validationErrors.tutor_ids, (error: string): string => `${t('admin.class_units.attributes.tutors')} ${error}`).join(', ')}
        </div>
      </div>

      <div className='grid place-items-end'>
        <button type='submit' className='bg-blue-500 hover:bg-blue-700 text-white py-3 px-6 rounded md:mt-10'>
          {t('admin.class_units.submit_button')}
        </button>
      </div>
    </form>
  )
}

export default ClassUnitForm
