import React, { type FormEvent, type ReactElement, useEffect, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParseDate } from '../../hooks/useParseDate'
import { useApiPost } from '../../hooks/api/useApiPost'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { type ProfileData, Roles } from '../../constants/types'
import AddressFormGroup from './AddressFormGroup'

import ProfileAvatarEditor from '../../components/profiles/ProfileAvatarEditor'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import { API_ADMIN_CLASS_UNITS_PATH } from '../../constants/apiRoutes'
import { map } from 'underscore'
import Select from '../common/Select'

const ProfileForm = (
  { profileData, url, method = 'post', redirect = '' }:
    { url: string, profileData?: ProfileData, method?: string, redirect?: string }
): ReactElement => {
  const { t } = useTranslation('translations')
  const { setInput, sendDataWithFiles, validationErrors, input } = useApiPost({ url, redirect, successFlashMessage: 'Pomyślnie zapisano' })
  const { fetchData: fetchClassUnits, data: classUnits } = useApiFetch({ url: API_ADMIN_CLASS_UNITS_PATH, onRender: false })
  const { toInputDate } = useParseDate()
  const { currentUser, fetchCurrentUser } = useContext(CurrentUserContext)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const initializeData = async (): Promise<void> => {
      try {
        if (!currentUser) await fetchCurrentUser()

        if (profileData) {
          setInput((_p: any) => ({
            ...profileData,
            deactivated_at: !!profileData.deactivated_at
          }))
        }

        if (currentUser?.admin) {
          await fetchClassUnits()
        }
      } catch (error) {
        console.error('Error initializing form:', error)
      } finally {
        setIsLoading(false)
      }
    }

    void initializeData()
  }, [currentUser, profileData])

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()
    await sendDataWithFiles({ fileKeys: ['avatar'], method })
  }

  if (isLoading) {
    return <div className="w-full h-64 flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  }

  return (
    <form method={method} onSubmit={handleSubmit}>
      <label className="text-lg font-medium ml-1">{t('users.profile.name')}</label>
      <br />
      <input
        value={input.name}
        className='px-2 py-1 border border-gray-300 rounded-lg mb-3 mt-3 h-12 w-full'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, name: e.target.value })) }}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.name, (error: string): string => `${t('users.profile.name')} ${error}`).join(', ')}
      </div>
      <br />

      <label className="text-lg font-medium ml-1">{t('users.profile.birth_place')}</label>
      <br />
      <input
        value={input.birth_place}
        className='px-2 py-1 border border-gray-300 rounded-lg mb-3 mt-3 h-12 w-full'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, birth_place: e.target.value })) }}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.birth_place, (error: string): string => `${t('users.profile.birth_place')} ${error}`).join(', ')}
      </div>
      <br />

      <label className="text-lg font-medium ml-1">{t('users.profile.birthday')}</label>
      <br />
      <input
        type="date"
        value={toInputDate(input.birthday)}
        className='px-2 py-1 border border-gray-300 rounded-lg mb-3 mt-3 h-12 w-full'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, birthday: e.target.value })) }}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.birthday, (error: string): string => `${t('users.profile.birthday')} ${error}`).join(', ')}
      </div>
      <br />

      <label className="text-lg font-medium ml-1">{t('users.profile.email')}</label>
      <br />
      <input
        value={input.email}
        className='px-2 py-1 border border-gray-300 rounded-lg mb-3 mt-3 h-12 w-full'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, email: e.target.value })) }}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.email, (error: string): string => `${t('users.profile.email')} ${error}`).join(', ')}
      </div>
      <br />

      <label className="text-lg font-medium ml-1">{t('users.profile.username')}</label>
      <br />
      <input
        value={input.username}
        className='px-2 py-1 border border-gray-300 rounded-lg mb-3 mt-3 h-12 w-full'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, username: e.target.value })) }}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.username, (error: string): string => `${t('users.profile.username')} ${error}`).join(', ')}
      </div>
      <br />

      <label className="text-lg font-medium ml-1">{t('users.profile.bank_number')}</label>
      <br />
      <input
        value={input.bank_number}
        className='px-2 py-1 border border-gray-300 rounded-lg mb-3 mt-3 h-12 w-full'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, bank_number: e.target.value })) }}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.bank_number, (error: string): string => `${t('users.profile.bank_number')} ${error}`).join(', ')}
      </div>
      <br />

      <label className="text-lg font-medium ml-1">{t('users.profile.pesel')}</label>
      <br />
      <input
        value={input.pesel}
        className='px-2 py-1 border border-gray-300 rounded-lg mb-3 mt-3 h-12 w-full'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, pesel: e.target.value })) }}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.pesel, (error: string): string => `${t('users.profile.pesel')} ${error}`).join(', ')}
      </div>
      <br />

      <label className="text-lg font-medium ml-1">{t('users.profile.telephone_number')}</label>
      <br />
      <input
        value={input.telephone_number}
        className='px-2 py-1 border border-gray-300 rounded-lg mb-3 mt-3 h-12 w-full'
        onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, telephone_number: e.target.value })) }}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.telephone_number, (error: string): string => `${t('users.profile.telephone_number')} ${error}`).join(', ')}
      </div>
      <br />

      {
        currentUser?.admin && (
          <>
            <label className="flex items-center cursor-pointer justify-start">
              <input
                type="checkbox"
                checked={input.deactivated_at}
                className="sr-only peer"
              />
              <div
                className="relative w-11 h-6 bg-gray-200 ring-gray-500 rounded-full
                             peer peer-checked:ring-blue-600 bg-gray-500
                             peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full
                             peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px]
                             after:start-[2px] after:bg-white after:border-gray-300 after:rounded-full
                             after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"
                onClick={() => setInput((prevInput) => ({ ...prevInput, deactivated_at: !input.deactivated_at }))}
              >
              </div>
              <div className="col-span-1 block lg:hidden"></div>
              <span className='text-base text-gray-600 col-span-4 lg:col-span-5 ml-3'>
                {t('users.profile.deactivated_at')}
              </span>
            </label>
            <div className='text-sm text-red-500'>
              {map(validationErrors.deactivated_at, (error: string): string => `${t('users.profile.deactivated_at')} ${error}`).join(', ')}
            </div>

            <br />

            {profileData && profileData?.role === Roles.student && (
              <>
                <label className="text-lg font-medium ml-1">{t('users.profile.class_unit')}</label>
                <Select
                  id="profile-class-unit-select"
                  placeholder={t('users.profile.class_unit_placeholder')}
                  value={input.class_unit_id}
                  options={map(classUnits, (classUnit) => ({ text: classUnit.name, value: classUnit.id }))}
                  onChange={(selectedValue: string) => {
                    setInput((prevInput: any) => ({
                      ...prevInput,
                      class_unit_id: selectedValue
                    }))
                  }}
                />
                <div className='text-sm text-red-500'>
                  {map(validationErrors.class_unit_id, (error: string): string => `${t('users.profile.class_unit')} ${error}`).join(', ')}
                </div>
              </>
            )}
          </>
        )
      }

      {profileData && profileData?.role === Roles.teacher && (
        <>
          <label className="text-lg font-medium ml-1">{t('users.profile.public_board')}</label>
          <br />
          <input
            value={input.public_board}
            className='px-2 py-1 border border-gray-300 rounded-lg mb-3 mt-3 h-12 w-full'
            onChange={(e) => { setInput((prevInput: any) => ({ ...prevInput, public_board: e.target.value })) }}
          />
          <div className="text-sm text-red-500">
            {map(validationErrors.public_board, (error: string): string => `${t('users.profile.public_board')} ${error}`).join(', ')}
          </div>
          <br />
        </>
      )}

      <label className="text-lg font-medium ml-1 block mb-2">{t('users.profile.avatar')}</label>
      <br />
      <ProfileAvatarEditor
        setInput={setInput}
        currentAvatar={profileData?.avatar?.url}
      />
      <div className="text-sm text-red-500">
        {map(validationErrors.avatar, (error: string): string => `${t('users.profile.avatar')} ${error}`).join(', ')}
      </div>
      <br />

      {map(['residential', 'registered', 'correspondence'], (type) => {
        return (
          <AddressFormGroup
            key={type}
            // FIXME: Type any below
            type={type as any}
            input={input}
            setInput={setInput}
            validationErrors={validationErrors}
          />
        )
      })}

      <div className="grid place-items-end">
        <button type="submit" className='bg-blue-500 hover:bg-blue-700 text-white py-3 px-6 rounded md:mt-10'>
          {t('users.profile.submit_button')}
        </button>
      </div>
    </form>
  )
}

export default ProfileForm
