import React, { useState, useContext, useCallback, useEffect, useRef } from 'react'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { useParseDate } from '../../hooks/useParseDate'
import { useTranslation } from 'react-i18next'
import { CurrentUserContext } from '../../contexts/users/CurrentUserContext'
import { TEACHER_ROLE } from '../../constants/roles'
import {
  API_STUDENT_LESSON_INSTANCES_PATH,
  API_TEACHER_LESSON_INSTANCES_PATH,
  API_LESSON_SLOTS_PATH,
  API_STUDENT_CALENDAR_EVENTS_PATH,
  API_TEACHER_CALENDAR_EVENTS_PATH,
  API_STUDENT_HOMEWORKS_PATH
} from '../../constants/apiRoutes'
import type {
  LessonInstanceData,
  CalendarEventData,
  PopoverState,
  HomeworkData
} from '../../constants/types'
import LessonInstancePopover from './components/LessonInstancePopover'
import CalendarEventPopover from './components/CalendarEventPopover'
import HomeworkPopover from './components/HomeworkPopover'
import { attendanceIcons } from './icons'
import { NavigationHeader } from './components/NavigationHeader'
import { TimeSlotEvents } from './components/TimeSlotEvents'
import { MonthView } from './components/MonthView'
import { useCalendarNavigation } from './hooks/useCalendarNavigation'

const CalendarComponent = (): JSX.Element => {
  const { t } = useTranslation('translations')
  const { toLocalHour, toLocalDateWithHour } = useParseDate()
  const { currentUser } = useContext(CurrentUserContext)

  const calendarRef = useRef<HTMLDivElement>(null)

  const isTeacher = currentUser?.role === TEACHER_ROLE
  const isUserLoaded = Boolean(currentUser)

  const { data: lessonInstances, fetchData: fetchLessonInstances } = useApiFetch({
    url: isTeacher ? API_TEACHER_LESSON_INSTANCES_PATH : API_STUDENT_LESSON_INSTANCES_PATH,
    onRender: false,
    enabled: isUserLoaded
  })

  const { data: lessonSlots } = useApiFetch({
    url: API_LESSON_SLOTS_PATH,
    enabled: isUserLoaded
  })

  const { data: calendarEvents } = useApiFetch({
    url: isTeacher ? API_TEACHER_CALENDAR_EVENTS_PATH : API_STUDENT_CALENDAR_EVENTS_PATH,
    enabled: isUserLoaded
  })

  const { data: homeworks } = useApiFetch({
    url: API_STUDENT_HOMEWORKS_PATH,
    enabled: isUserLoaded && !isTeacher
  })

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  const {
    weekBoundaries,
    viewState,
    setViewState,
    handlePrev,
    handleNext,
    handleToday
  } = useCalendarNavigation(
    fetchLessonInstances,
    calendarRef,
    isMobile
  )

  useEffect(() => {
    if (lessonInstances && Array.isArray(lessonInstances) && lessonInstances.length > 0) {
      const timer = setTimeout(() => {
        setViewState(prev => ({ ...prev }))
      }, 100)
      return () => { clearTimeout(timer) }
    }
  }, [lessonInstances])

  useEffect(() => {
    if (isUserLoaded) {
      fetchLessonInstances({
        starts_at_from: weekBoundaries.weekStart.toISOString(),
        starts_at_to: weekBoundaries.weekEnd.toISOString()
      })
    }
  }, [isUserLoaded, weekBoundaries.weekStart, weekBoundaries.weekEnd])

  const [popover, setPopover] = useState<PopoverState<LessonInstanceData>>({
    isOpen: false,
    data: null,
    position: null
  })

  const [eventPopover, setEventPopover] = useState<PopoverState<CalendarEventData>>({
    isOpen: false,
    data: null,
    position: null
  })

  const [homeworkPopover, setHomeworkPopover] = useState<PopoverState<HomeworkData>>({
    isOpen: false,
    data: null,
    position: null
  })

  const handleClickOutside = useCallback((e: MouseEvent): void => {
    if ((popover.isOpen || eventPopover.isOpen || homeworkPopover.isOpen) && !e.defaultPrevented) {
      setPopover({ isOpen: false, data: null, position: null })
      setEventPopover({ isOpen: false, data: null, position: null })
      setHomeworkPopover({ isOpen: false, data: null, position: null })
    }
  }, [popover.isOpen, eventPopover.isOpen, homeworkPopover.isOpen])

  useEffect(() => {
    const handleResize = (): void => {
      const newIsMobile = window.innerWidth < 768
      setIsMobile(newIsMobile)

      if (popover.isOpen && popover.data) {
        const button = document.querySelector(`[data-lesson-id="${popover.data.id}"]`) as HTMLElement
        if (button) {
          const rect = button.getBoundingClientRect()
          const container = button.closest('.grid')

          if (container) {
            const containerRect = container.getBoundingClientRect()

            if (!newIsMobile) {
              setPopover(prev => ({
                ...prev,
                position: {
                  top: rect.top + window.scrollY - (rect.height / 2),
                  left: Math.min(
                    rect.right + window.scrollX,
                    containerRect.right - 300
                  )
                }
              }))
            } else {
              setPopover(prev => ({ ...prev, position: null }))
            }
          }
        }
      }

      if (eventPopover.isOpen && eventPopover.data) {
        const button = document.querySelector(`[data-event-id="${eventPopover.data.id}"]`) as HTMLElement
        if (button) {
          const rect = button.getBoundingClientRect()
          const container = button.closest('.grid')

          if (container) {
            const containerRect = container.getBoundingClientRect()

            if (!newIsMobile) {
              setEventPopover(prev => ({
                ...prev,
                position: {
                  top: rect.top + window.scrollY - (rect.height / 2),
                  left: Math.min(
                    rect.right + window.scrollX,
                    containerRect.right - 300
                  )
                }
              }))
            } else {
              setEventPopover(prev => ({ ...prev, position: null }))
            }
          }
        }
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [popover.isOpen, popover.data, eventPopover.isOpen, eventPopover.data])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  const handleHomeworkClick = (homework: HomeworkData, e: React.MouseEvent): void => {
    e.stopPropagation()

    if (homeworkPopover.isOpen && homeworkPopover.data?.id === homework.id) {
      setHomeworkPopover({ isOpen: false, data: null, position: null })
      return
    }

    setPopover({ isOpen: false, data: null, position: null })
    setEventPopover({ isOpen: false, data: null, position: null })
    setHomeworkPopover({ isOpen: false, data: null, position: null })

    const button = e.currentTarget as HTMLElement
    const buttonRect = button.getBoundingClientRect()
    const gridContainer = button.closest('.grid')

    if (!gridContainer) return

    const columns = Array.from(gridContainer.querySelectorAll('.border-r')).filter(el =>
      !el.classList.contains('sticky')
    )
    const currentColumn = button.closest('.border-r')
    const dayIndex = columns.indexOf(currentColumn as Element) % 5

    const isLastTwoColumns = dayIndex >= 3

    const position = isMobile
      ? null
      : {
        top: buttonRect.top + window.scrollY - 344,
        left: isLastTwoColumns
          ? buttonRect.left - 362
          : buttonRect.right - 38
      }

    setHomeworkPopover({
      isOpen: true,
      data: homework,
      position
    })
  }

  const handleCalendarEventClick = (event: CalendarEventData, e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation()

    if (eventPopover.isOpen && eventPopover.data?.id === event.id) {
      setEventPopover({ isOpen: false, data: null, position: null })
      return
    }

    setPopover({ isOpen: false, data: null, position: null })
    setEventPopover({ isOpen: false, data: null, position: null })
    setHomeworkPopover({ isOpen: false, data: null, position: null })

    const button = e.currentTarget
    const buttonRect = button.getBoundingClientRect()
    const gridContainer = button.closest('.grid')

    const multiDayContainer = button.closest('.col-span-5')
    const isMultiDayEvent = Boolean(multiDayContainer)

    if (!gridContainer) return

    let dayIndex

    if (isMultiDayEvent) {
      const gridRect = gridContainer?.getBoundingClientRect()
      if (!gridRect) return

      const popoverWidth = 304
      const padding = 20
      const spaceOnRight = gridRect.right - buttonRect.right - padding
      const hasSpaceOnRight = spaceOnRight >= popoverWidth

      const eventWidth = buttonRect.width
      const gridWidth = gridRect.width
      const isFullWeekEvent = eventWidth > gridWidth * 0.8

      const position = isMobile
        ? null
        : {
          top: buttonRect.top + window.scrollY - 344,
          left: isFullWeekEvent
            ? gridRect.right - popoverWidth - padding
            : hasSpaceOnRight
              ? buttonRect.right + padding - 58
              : buttonRect.left - popoverWidth - padding - 38
        }

      setEventPopover({
        isOpen: true,
        data: event,
        position
      })
      return
    } else {
      const columns = Array.from(gridContainer.querySelectorAll('.border-r')).filter(el =>
        !el.classList.contains('sticky')
      )
      const currentColumn = button.closest('.border-r')
      dayIndex = columns.indexOf(currentColumn as Element) % 5
    }

    const isLastTwoColumns = dayIndex >= 3

    const position = isMobile
      ? null
      : {
        top: buttonRect.top + window.scrollY - 344,
        left: isLastTwoColumns
          ? buttonRect.left - 362
          : buttonRect.right - 38
      }

    setEventPopover({
      isOpen: true,
      data: event,
      position
    })
  }

  const handleLessonClick = (event: LessonInstanceData, e: React.MouseEvent): void => {
    e.stopPropagation()

    if (popover.isOpen && popover.data?.id === event.id) {
      setPopover({ isOpen: false, data: null, position: null })
      return
    }

    setPopover({ isOpen: false, data: null, position: null })
    setEventPopover({ isOpen: false, data: null, position: null })
    setHomeworkPopover({ isOpen: false, data: null, position: null })

    const button = e.currentTarget as HTMLElement
    const buttonRect = button.getBoundingClientRect()
    const gridContainer = button.closest('.grid')

    if (!gridContainer) return

    const columns = Array.from(gridContainer.querySelectorAll('.border-r')).filter(el =>
      !el.classList.contains('sticky')
    )
    const currentColumn = button.closest('.border-r')
    const dayIndex = columns.indexOf(currentColumn as Element) % 5

    const isLastTwoColumns = dayIndex >= 3

    const position = isMobile
      ? null
      : {
        top: buttonRect.top + window.scrollY - 344,
        left: isLastTwoColumns
          ? buttonRect.left - 362
          : buttonRect.right - 36
      }

    setPopover({
      isOpen: true,
      data: event,
      position
    })
  }

  const getWeekdayLabel = (date: Date): string => {
    const weekdays = [
      t('calendar.days_of_week.monday'),
      t('calendar.days_of_week.tuesday'),
      t('calendar.days_of_week.wednesday'),
      t('calendar.days_of_week.thursday'),
      t('calendar.days_of_week.friday'),
      t('calendar.days_of_week.saturday'),
      t('calendar.days_of_week.sunday')
    ]
    const dayIndex = date.getDay()
    const adjustedIndex = dayIndex === 0 ? 6 : dayIndex - 1
    return weekdays[adjustedIndex]
  }

  const handleMonthChange = (newDate: Date): void => {
    setViewState(prev => ({
      ...prev,
      currentDate: newDate
    }))
  }

  const handleDayClick = (date: Date): void => {
    console.log('Changing view state to week in handleDayClick')
    setViewState(prev => ({
      ...prev,
      view: 'week',
      currentDate: date
    }))

    requestAnimationFrame(() => {
      setTimeout(() => {
        const dayIndex = date.getDay() - 1
        if (dayIndex >= 0) {
          const columns = Array.from(calendarRef.current?.querySelectorAll('.border-r') ?? [])
            .filter(el => !el.classList.contains('sticky'))
          const dayColumn = columns[dayIndex]
          if (dayColumn) {
            dayColumn.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        }
      }, 100)
    })
  }

  const renderCurrentView = (): JSX.Element => {
    switch (viewState.view) {
    case 'month':
      return (
        <MonthView
          currentDate={viewState.currentDate}
          calendarEvents={calendarEvents || []}
          homeworks={homeworks || []}
          lessonInstances={isTeacher ? [] : (lessonInstances || [])}
          getWeekdayLabel={getWeekdayLabel}
          onMonthChange={handleMonthChange}
          isMobile={isMobile}
          onDayClick={handleDayClick}
        />
      )
    case 'week':
    default:
      return (
        <TimeSlotEvents
          calendarRef={calendarRef}
          isMobile={isMobile}
          weekBoundaries={weekBoundaries}
          lessonInstances={lessonInstances || []}
          lessonSlots={lessonSlots || []}
          homeworks={homeworks || []}
          calendarEvents={calendarEvents || []}
          handleLessonClick={handleLessonClick}
          handleCalendarEventClick={handleCalendarEventClick}
          handleHomeworkClick={handleHomeworkClick}
          getWeekdayLabel={getWeekdayLabel}
          toLocalHour={toLocalHour}
          toLocalDateWithHour={toLocalDateWithHour}
          activeLessonId={popover.isOpen ? popover.data?.id : undefined}
          activeCalendarEventId={eventPopover.isOpen ? eventPopover.data?.id : undefined}
          activeHomeworkId={homeworkPopover.isOpen ? homeworkPopover.data?.id : undefined}
        />
      )
    }
  }

  return (
    <div className="relative pb-[300px]">
      <div className="bg-white overflow-hidden py-2">
        <div className="p-2 sm:px-6 lg:px-4">
          <NavigationHeader
            weekBoundaries={weekBoundaries}
            viewState={viewState}
            onPrev={handlePrev}
            onNext={handleNext}
            onToday={handleToday}
            onViewChange={(view) => { setViewState(prev => ({ ...prev, view })) }}
          />
          <div className="flex-auto mt-4">{renderCurrentView()}</div>
        </div>
        {popover.isOpen && popover.data && (
          <LessonInstancePopover
            data={popover.data}
            position={popover.position}
            onClose={(): void => {
              setPopover({ isOpen: false, data: null, position: null })
            }}
            attendanceIcons={attendanceIcons}
          />
        )}
        {eventPopover.isOpen && eventPopover.data && (
          <CalendarEventPopover
            data={eventPopover.data}
            position={eventPopover.position}
            onClose={(): void => {
              setEventPopover({ isOpen: false, data: null, position: null })
            }}
          />
        )}
        {homeworkPopover.isOpen && homeworkPopover.data && (
          <HomeworkPopover
            data={homeworkPopover.data}
            position={homeworkPopover.position}
            onClose={(): void => {
              setHomeworkPopover({ isOpen: false, data: null, position: null })
            }}
          />
        )}
      </div>
    </div>
  )
}

export default CalendarComponent
