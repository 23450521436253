import React, { type ReactElement } from 'react'
import type { ChevronIconProps } from '../../../../constants/types'

const ChevronIcon = ({ collapsed }: ChevronIconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className={`h-4 w-4 text-gray-500 transition-transform duration-300 ease-in-out ${collapsed ? 'rotate-180' : ''}`}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
  </svg>
)

export default ChevronIcon
