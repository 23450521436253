import React, { type ReactElement, useContext } from 'react'
import { Link } from 'react-router-dom'
import { ADMIN_EDIT_CLASS_UNIT_PATH } from '../../../constants/adminRoutes'
import { API_ADMIN_EDIT_CLASS_UNIT_PATH } from '../../../constants/apiRoutes'
import { type ClassUnitData, FlashType } from '../../../constants/types'
import { useTranslation } from 'react-i18next'
import { useApiPost } from '../../../hooks/api/useApiPost'
import { map } from 'underscore'
import { FlashContext } from '../../../contexts/flash/FlashContext'

const ClassUnitListItem = ({ classUnit }: { classUnit: ClassUnitData }): ReactElement => {
  const { t } = useTranslation('translations')
  const { setFlash } = useContext(FlashContext)

  const { sendData: deleteClassUnit } = useApiPost({
    url: API_ADMIN_EDIT_CLASS_UNIT_PATH.replace(':id', classUnit?.id?.toString())
  })

  const sendDeleteRequest = async (): Promise<void> => {
    try {
      await deleteClassUnit({ method: 'delete' })
      window.location.reload()
      setFlash({ message: 'Pomyślnie usunięto', type: FlashType.success })
    } catch (error) {
      setFlash({ message: 'Nie udało się usunąć wydarzenia', type: FlashType.error })
    }
  }

  const handleDeleteClick = (event: React.MouseEvent<SVGSVGElement>): void => {
    const confirmMessage = event.currentTarget.getAttribute('data-confirm')
    if (!confirmMessage || window.confirm(confirmMessage)) {
      sendDeleteRequest().catch(console.error)
    }
  }

  return (
    <div className="p-4">
      <div className="lg:hidden space-y-4">
        <div className="flex flex-col space-y-2">
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">Name:</span>
            <span className="text-sm">{classUnit?.name}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">Color:</span>
            <div className="flex items-center space-x-2">
              <span className="text-sm">{classUnit?.color}</span>
              <div className={`h-5 w-5 rounded-md bg-${classUnit.color}`} />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">Tutors:</span>
            <span className="text-sm">{map(classUnit?.tutors, (tutor) => tutor?.user?.name).join(', ')}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-500">Grade:</span>
            <span className="text-sm">{classUnit?.grade}</span>
          </div>
        </div>
        <div className="flex justify-between items-center pt-2">
          <Link
            to={ADMIN_EDIT_CLASS_UNIT_PATH.replace(':id', classUnit?.id?.toString())}
            className="inline-flex items-center px-3 py-1 border border-[#3758F9] rounded-full text-sm text-[#3758F9]"
          >
            {t('admin.class_units.edit_button')}
          </Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5 cursor-pointer"
            onClick={handleDeleteClick}
            data-confirm="Na pewno chcesz usunąć klasę?"
          >
            <path
              fillRule="evenodd"
              d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>

      <div className="hidden lg:grid lg:grid-cols-12 lg:gap-4 lg:items-center">
        <div className="col-span-3 truncate">{classUnit?.name}</div>
        <div className="col-span-2 flex items-center space-x-2">
          <span className="truncate">{classUnit?.color}</span>
          <div className={`h-5 w-5 rounded-md bg-${classUnit.color}`} />
        </div>
        <div className="col-span-3 truncate">{map(classUnit?.tutors, (tutor) => tutor?.user?.name).join(', ')}</div>
        <div className="col-span-2 truncate">{classUnit?.grade}</div>
        <div className="col-span-2 flex items-center space-x-2">
          <Link
            to={ADMIN_EDIT_CLASS_UNIT_PATH.replace(':id', classUnit?.id?.toString())}
            className="inline-flex items-center px-3 py-1 border border-[#3758F9] rounded-full text-sm text-[#3758F9]"
          >
            {t('admin.class_units.edit_button')}
          </Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5 cursor-pointer"
            onClick={handleDeleteClick}
            data-confirm="Na pewno chcesz usunąć klasę?"
          >
            <path
              fillRule="evenodd"
              d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default ClassUnitListItem
