import type { GradeData, GradeTemplate, StudentData } from '../../../constants/types'

export const findStudentGrade = (
  studentId: number,
  gradeTemplate: GradeTemplate
): GradeData | undefined => {
  return gradeTemplate.grades.find((grade) => grade.student.id === studentId)
}

export const getNextStudent = (
  currentStudent: StudentData,
  students: StudentData[]
): StudentData | undefined => {
  const currentIndex = students.findIndex(s => s.id === currentStudent.id)
  return students[currentIndex + 1]
}
