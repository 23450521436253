// Common
export const API_USERS_PATH = '/users'
export const API_LOGIN_PATH = '/users/sessions'
export const API_FORGOT_PASSWORD_PATH = '/users/forgot_passwords'
export const API_RESET_PASSWORD_PATH = '/users/reset_passwords'
export const API_VALIDATE_RESET_PASSWORD_TOKEN_PATH = '/users/reset_passwords/token_validations'
export const API_REFRESH_TOKEN_PATH = '/users/refresh_tokens'
export const API_MESSAGES_PATH = '/messages'
export const API_CONVERSATIONS_PATH = '/messages/conversations'
export const API_ADMIN_CALENDAR_EVENT_TYPES_PATH = '/admin/calendar_event_types'
export const API_PINNED_NEWS_PATH = '/news/pinned'
export const API_NEWS_PATH = '/news'
export const API_NEWS_DETAILS_PATH = '/news/:news_id/details'
export const API_NEWS_READINGS_PATH = '/news/:news_id/news_readings'
export const API_NEWS_NOT_READ_REQUIRED_PATH = '/news/not_read_required'
export const API_NEWS_COMMENTS_PATH = '/news/:news_id/comments'
export const API_NEWS_COMMENTS_REPLIES_PATH = '/news/:news_id/comments/:comment_id/replies'
export const API_SEARCHES_PATH = '/searches'
export const API_SEARCHES_TYPES_PATH = '/searches/types'
export const API_USERS_PROFILE_PATH = '/users/profiles/:id'
export const API_LESSON_SLOTS_PATH = '/lesson_slots'

// Student
export const API_STUDENT_LESSON_INSTANCES_PATH = '/students/lesson_instances'
export const API_STUDENT_CALENDAR_EVENTS_PATH = '/students/calendar_events'
export const API_STUDENT_HOMEWORKS_PATH = '/students/homeworks'
export const API_STUDENT_HOMEWORK_PATH = '/students/homeworks/:id'
export const API_STUDENT_HOMEWORK_SOLUTIONS_PATH = '/students/homeworks/:homeworkId/homework_solutions'
export const API_STUDENT_EDIT_HOMEWORK_SOLUTIONS_PATH = '/students/homeworks/:homeworkId/homework_solutions/:id'
export const API_STUDENT_LESSONS_PATH = '/students/lessons'
export const API_STUDENT_LESSON_PATH = '/students/lessons/:id'
export const API_STUDENT_LESSON_GRADES_PATH = '/students/lessons/:id/grades'
export const API_STUDENT_GRADES_PATH = '/students/grades'

// Teacher
export const API_TEACHER_CALENDAR_EVENTS_PATH = '/teachers/calendar_events'
export const API_TEACHER_LESSON_INSTANCES_PATH = '/teachers/lesson_instances'
export const API_TEACHER_HOMEWORKS_PATH = '/teachers/homeworks'
export const API_TEACHER_LESSON_HOMEWORKS_PATH = '/teachers/lessons/:id/homeworks'
export const API_TEACHER_HOMEWORK_PATH = '/teachers/homeworks/:id/homework_solutions'
export const API_TEACHER_EDIT_HOMEWORK_PATH = '/teachers/homeworks/:id'
export const API_TEACHER_EDIT_HOMEWORK_SOLUTION_PATH = '/teachers/homework_solutions/:id'
export const API_TEACHER_LESSONS_PATH = '/teachers/lessons'
export const API_TEACHER_LESSON_PATH = '/teachers/lessons/:id'
export const API_TEACHER_LESSON_GRADES_PATH = '/teachers/lessons/:id/grades'
export const API_TEACHER_LESSON_GRADES_EDIT_PATH = '/teachers/lessons/:lesson_id/grades/:id'
export const API_TEACHER_LESSON_GRADE_GROUPS_PATH = '/teachers/lessons/:id/grade_groups'
export const API_TEACHER_LESSON_GRADE_GROUPS_EDIT_PATH = '/teachers/lessons/:id/grade_groups'
export const API_TEACHER_LESSON_GRADE_TEMPLATES_PATH = '/teachers/lessons/:id/grade_templates'
export const API_TEACHER_LESSON_GRADE_TEMPLATES_EDIT_PATH = '/teachers/lessons/:id/grade_templates'

// Admin
export const API_ADMIN_SCHOOLS_PATH = '/admin/schools'
export const API_ADMIN_NEWS_PATH = '/admin/news'
export const API_NEWS_EXPIRE_PATH = '/admin/news/expirations'
export const API_ADMIN_EDIT_NEWS_PATH = '/admin/news/:id'
export const API_ADMIN_CALENDAR_EVENTS_PATH = '/admin/calendar_events'
export const API_ADMIN_EDIT_CALENDAR_EVENT_PATH = '/admin/calendar_events/:id'
export const API_ADMIN_DOCUMENTS_PATH = '/admin/documents'
export const API_ADMIN_EDIT_DOCUMENT_PATH = '/admin/documents/:id'
export const API_ADMIN_TEACHERS_PATH = '/admin/teachers'
export const API_ADMIN_LESSON_SLOTS_PATH = '/admin/lesson_slots'
export const API_ADMIN_EDIT_LESSON_SLOT_PATH = '/admin/lesson_slots/:id'
export const API_ADMIN_CLASSROOMS_PATH = '/admin/classrooms'
export const API_ADMIN_PAGINATED_CLASSROOMS_PATH = '/admin/classrooms?paginated=true'
export const API_ADMIN_EDIT_CLASSROOM_PATH = '/admin/classrooms/:id'
export const API_ADMIN_LESSONS_PATH = '/admin/lessons'
export const API_ADMIN_EDIT_LESSON_PATH = '/admin/lessons/:id'
export const API_ADMIN_SUBJECTS_PATH = '/admin/subjects'
export const API_ADMIN_EDIT_SUBJECT_PATH = '/admin/subjects/:id'
export const API_ADMIN_SUBJECT_COLORS_PATH = '/admin/subject_colors'
export const API_ADMIN_SEMESTERS_PATH = '/admin/semesters'
export const API_ADMIN_GROUPS_PATH = '/admin/groups'
export const ADMIN_SEMESTERS_PATH = '/admin/semesters'
export const API_ADMIN_CLASS_UNITS_PATH = '/admin/class_units'
export const API_ADMIN_EDIT_CLASS_UNIT_PATH = '/admin/class_units/:id'
export const API_ADMIN_CLASS_UNIT_COLORS_PATH = '/admin/class_units/colors'
export const ADMIN_ADMIN_GROUPS_PATH = '/admin/groups'
export const API_NEWS_EDIT_COMMENTS_PATH = '/admin/news/comments/:id'
export const API_ADMIN_SCHOOL_YEARS_PATH = '/admin/school_years'
export const API_ADMIN_STUDENTS_PATH = '/admin/students'
export const API_ADMIN_NEWS_UPLOADS_PATH = '/admin/news/uploads'
export const API_ADMIN_ROLES_PATH = '/admin/roles'
export const API_ADMIN_LESSON_SCHEDULE_IMPORTS_PATH = '/admin/lesson_schedule_imports'
export const API_ADMIN_EDIT_LESSON_SCHEDULE_IMPORTS_PATH = '/admin/lesson_schedule_imports/:id'
export const API_ADMIN_LESSON_SCHEDULE_IMPORTS_IMPORTS_PATH = '/admin/lesson_schedule_imports/:id/imports'
export const API_ADMIN_LESSON_SCHEDULES_PATH = '/admin/lesson_schedules'
export const API_ADMIN_SUBJECT_LEVELS_PATH = '/admin/subject_levels'
export const API_ADMIN_DEACTIVATE_USER_PATH = '/admin/users/:id/deactivations'
