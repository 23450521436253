import React, { type ReactElement } from 'react'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { API_STUDENT_GRADES_PATH } from '../../constants/apiRoutes'

const StudentGradesView = (): ReactElement => {
  const { data: grades } = useApiFetch({ url: API_STUDENT_GRADES_PATH })

  return <div>{JSON.stringify(grades)}</div>
}

export default StudentGradesView
