import React, { type ReactElement } from 'react'
import type { GradeCellProps } from '../../../constants/types'
import CommentIcon from './icons/CommentIcon'
import ChevronRightIcon from './icons/ChevronRightIcon'

const GradeCell = ({
  student,
  template,
  grade,
  editingCell,
  onGradeClick,
  onGradeChange,
  onKeyPress,
  onOpenGradeEdit
}: GradeCellProps): ReactElement => {
  return (
    <td
      className="px-4 py-2 border border-gray-200 cursor-pointer hover:bg-gray-50 relative"
      onClick={onGradeClick}
    >
      <div className="w-[150px] flex items-center gap-2">
        {editingCell?.studentId === student.id && editingCell?.templateId === template.id
          ? (
            <input
              autoFocus
              type="text"
              value={editingCell.value}
              onChange={(e) => { onGradeChange(e.target.value) }}
              onKeyDown={onKeyPress}
              className="w-4/5 px-[5px] py-[2px] text-sm font-medium text-gray-900 border-none focus:ring-0 p-0"
            />
          )
          : (
            <>
              <div className={`text-sm font-medium truncate ${
                grade?.grade_type === 'present'
                  ? 'text-gray-900'
                  : 'text-gray-500 italic'
              }`}>
                {grade?.grade_type === 'present' ? grade?.value : grade?.grade_type}
              </div>

              {grade?.note && grade?.grade_type === 'present' && (
                <div onClick={(e) => {
                  e.stopPropagation()
                  onOpenGradeEdit()
                }}>
                  <CommentIcon />
                </div>
              )}
            </>
          )
        }
        {editingCell?.studentId === student.id && editingCell?.templateId === template.id && (
          <div
            className="absolute right-2"
            onClick={(e) => {
              e.stopPropagation()
              onOpenGradeEdit()
            }}
          >
            <ChevronRightIcon />
          </div>
        )}
      </div>
    </td>
  )
}

export default GradeCell
