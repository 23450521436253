import React, { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { useApiFetch } from '../../hooks/api/useApiFetch'
import { API_TEACHER_LESSON_GRADES_PATH, API_TEACHER_LESSON_PATH } from '../../constants/apiRoutes'
import TeacherLessonGrades from '../../components/teachers/grades/TeacherLessonGrades'

export const TeacherLessonGradesView = (): ReactElement => {
  const queryParams = useParams()
  const lessonId = queryParams.id ?? 'defaultLessonId'

  const { data: gradesData, fetchData: fetchGradesData } = useApiFetch({
    url: API_TEACHER_LESSON_GRADES_PATH.replace(':id', lessonId),
    enabled: !!lessonId
  })

  const { fetchData: fetchLessonData } = useApiFetch({
    url: API_TEACHER_LESSON_PATH.replace(':id', lessonId),
    enabled: !!lessonId
  })

  return (
    <div className="grid h-screen">
      <div className="lg:px-12 pb-20 pt-2 card lg:mx-1 w-screen">
        <TeacherLessonGrades
          categories={gradesData}
          fetchLessonData={fetchLessonData}
          fetchGradesData={fetchGradesData}
          lessonId={lessonId}
        />
      </div>
    </div>
  )
}

export default TeacherLessonGradesView
