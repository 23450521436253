import React, { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import type { ClassUnitsListProps } from '../../../constants/types'

const ClassUnitsList = ({ lesson, t }: ClassUnitsListProps): ReactElement => {
  return (
    <div className="flex flex-row space-x-4 lg:mx-4 mt-3">
      <div className="flex flex-col items-end w-24">
        <div className="font-['DM Sans'] text-zinc-700">
          {t('lessons.heading.class_units')}:
        </div>
      </div>
      <div className="flex flex-col items-start flex-1">
        {lesson?.class_units && lesson.class_units.length > 0 && (
          <div className="flex gap-3 font-['DM Sans']">
            {lesson.class_units.map((unit) => (
              <Link
                key={unit.name}
                to=""
                className={`text-${unit.color} bg-${unit.color} bg-opacity-20 py-0.5 px-3 rounded-xl`}
              >
                {unit.name}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ClassUnitsList
