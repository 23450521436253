import React, { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import DefaultAvatar from '../../../assets/default_avatar.svg'
import { USER_PROFILE_PATH } from '../../../constants/routes'
import type { StudentCellProps } from '../../../constants/types'

const StudentCell = ({ student, index }: StudentCellProps): ReactElement => {
  return (
    <td className="sticky left-0 z-10 bg-white hover:bg-gray-50 lg:px-5 px-2 py-2 border border-gray-200 lg:w-[300px] w-[220px] after:absolute after:top-0 after:right-[-8px] after:content-[''] after:w-[7px] after:h-full after:bg-gradient-to-r after:from-black/[.07] after:to-transparent">
      <Link
        to={USER_PROFILE_PATH.replace(':id', student.user.id.toString())}
        className="flex items-center gap-3"
      >
        <span className="text-gray-500 min-w-[20px] lg:inline-block hidden">{index + 1}.</span>
        <div className="relative group">
          <img
            src={student.user.avatar?.url ?? DefaultAvatar}
            className="object-cover h-6 w-6 rounded-full flex-shrink-0"
            alt={`Avatar of ${student.user.name}`}
          />
          <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block bg-white p-1 rounded-lg shadow-lg z-20 w-[100px] h-[100px]">
            <img
              src={student.user.avatar?.url ?? DefaultAvatar}
              className="w-full h-full object-cover rounded-lg"
              alt={`Avatar of ${student.user.name}`}
            />
          </div>
        </div>
        <span className="text-sm font-medium text-gray-600 truncate">{student.user.name}</span>
      </Link>
    </td>
  )
}

export default StudentCell
