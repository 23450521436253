import React from 'react'
import type { CalendarEventData } from '../../../constants/types'
import { useParseDate } from '../../../hooks/useParseDate'
import { useTranslation } from 'react-i18next'

const CalendarEventButton = ({
  event,
  onClick,
  isActive = false
}: {
  event: CalendarEventData
  onClick: (event: CalendarEventData, e: React.MouseEvent<HTMLButtonElement>) => void
  isActive?: boolean
}): JSX.Element | null => {
  const { toLocalHour } = useParseDate()
  const { t } = useTranslation('translations')

  const bgColorClass = {
    normal: 'bg-yellow-100',
    holiday: 'bg-green-100',
    default: 'bg-gray-100'
  }[event.event_type ?? 'default']

  if (!event?.title) {
    return null
  }

  return (
    <button
      className={`px-2 py-2 h-full flex flex-col w-full items-start text-xs font-extrabold text-left border ${
        isActive ? 'border-blue-500' : 'border-gray-200'
      } ${bgColorClass} rounded shadow-sm`}
      onClick={(e) => { onClick(event, e) }}
    >
      <div className="w-full truncate text-[11px]">
        {event.event_type === 'normal' ? event.title : t('calendar.holiday')}
      </div>
      {event.event_type === 'normal' && (
        <>
          <div className="w-full truncate text-gray-600 font-normal text-[9px]">
            {event.description}
          </div>
          <div className="w-full truncate font-normal text-[9px]">
            {event.all_day === 'true'
              ? (
                <span className="bg-gray-200 text-gray-600 px-1.5 py-0.5 rounded text-[9px]">
                  {t('calendar.all_day')}
                </span>
              )
              : (
                `${toLocalHour(event.starts_at)} - ${toLocalHour(event.ends_at)}`
              )
            }
          </div>
        </>
      )}
    </button>
  )
}

export { CalendarEventButton }
