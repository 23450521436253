import React, { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import DefaultAvatar from '../../../assets/default_avatar.svg'
import { USER_PROFILE_PATH } from '../../../constants/routes'
import type { TeachersListProps } from '../../../constants/types'

const TeachersList = ({ lesson, t }: TeachersListProps): ReactElement => {
  return (
    <div className="flex flex-row space-x-4 lg:mx-4">
      <div className="flex flex-col items-end w-24">
        <div className="font-['DM Sans'] text-zinc-700">
          {t('lessons.heading.teacher')}:
        </div>
      </div>
      <div className="flex flex-col items-start flex-1">
        <div className="flex flex-col gap-2">
          {lesson?.teachers?.map((teacher) => (
            <Link
              key={teacher.id}
              to={USER_PROFILE_PATH.replace(':id', teacher.user?.id.toString())}
              className="flex gap-3 lg:items-center items-start font-['DM Sans']"
            >
              <img
                src={teacher.user?.avatar.url ?? DefaultAvatar}
                className="object-cover cursor-pointer h-7 w-7 rounded-full flex-shrink-0 mt-0.5"
                alt="Avatar"
              />
              <span className="lg:pt-0 pt-1">{teacher.user?.name}</span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TeachersList
