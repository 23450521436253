import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useApiFetch } from '../../../hooks/api/useApiFetch'
import { map } from 'underscore'
import { Link } from 'react-router-dom'
import { API_ADMIN_CLASS_UNITS_PATH } from '../../../constants/apiRoutes'
import { ADMIN_NEW_CLASS_UNIT_PATH } from '../../../constants/adminRoutes'
import Plus from '../../../assets/icons/plus.svg'
import Pagination from '../../../components/common/Pagination'
import ClassUnitListItem from '../../../components/admin/class_units/ClassUnitListItem'
import ClassUnitsFilters from '../../../components/admin/class_units/ClassUnitsFilters'

const ClassUnitsView = (): ReactElement => {
  const { data: classUnits, fetchData, query, setQuery } = useApiFetch({ url: API_ADMIN_CLASS_UNITS_PATH, paginated: true, defaultQuery: { paginated: 'true' } })
  const { t } = useTranslation('translations')

  return (
    <div className="w-full px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto py-6 sm:py-8">
        <div className="flex flex-col sm:flex-row justify-between gap-4 mb-6">
          <h1 className="text-xl font-bold font-['Inter'] leading-tight">
            {t('admin.class_units.h1')}
          </h1>

          <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center">
            <ClassUnitsFilters
              fetchClassUnits={fetchData}
              query={query}
              setQuery={setQuery}
            />

            <Link
              to={ADMIN_NEW_CLASS_UNIT_PATH}
              className="inline-flex items-center px-4 py-2 bg-[#3758F9] rounded-lg text-white text-sm whitespace-nowrap"
            >
              {t('admin.class_units.add_button')}
              <img src={Plus} alt="plus.svg" className="ml-2 w-4 h-4" />
            </Link>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="hidden lg:grid lg:grid-cols-12 bg-[#F9FAFB] py-4 px-4">
            <div className="font-['Inter'] col-span-3">{t('admin.class_units.attributes.name')}</div>
            <div className="font-['Inter'] col-span-2">{t('admin.class_units.attributes.color')}</div>
            <div className="font-['Inter'] col-span-3">{t('admin.class_units.attributes.tutors')}</div>
            <div className="font-['Inter'] col-span-2">{t('admin.class_units.attributes.grade')}</div>
            <div className="col-span-2" />
          </div>

          <div className="divide-y divide-gray-200">
            {map(classUnits?.data, (classUnit): ReactElement => (
              <ClassUnitListItem
                classUnit={classUnit}
                key={classUnit.id}
              />
            ))}
          </div>

          <div className="border-t border-gray-200">
            <div className="px-4 py-4">
              <Pagination
                paginationMeta={classUnits?.meta}
                fetchData={fetchData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClassUnitsView
