import type React from 'react'

export interface CalendarEventData {
  id: number
  title: string
  description: string
  all_classes?: boolean
  class_units?: ClassUnitData[]
  roles?: RoleData[]
  starts_at: Date
  ends_at: Date
  isMultiDay?: boolean
  spanDays?: number
  event_type: string
  all_day: string
}

export interface CalendarItemData {
  id: number
  title: string
  description: string
  event_type?: string
  starts_at: Date
  ends_at: Date
}

export interface DocumentItemData {
  id: number
  name: string
  created_at: Date
  files: FileData[]
}

export interface LessonScheduleImportData {
  id: number
  status: string
  failure_reason: string
  created_at: Date
  import_file: FileData
  possible_duplicates: Record<string, Array<Record<string, string>>>
}

export interface FileData {
  id: number
  filename: string
  url: string
}

export interface SchoolListItemData {
  id: number
  name: string
  patron: string | null
}

export interface ValidationError {
  error: string
  details: Record<string, any>
}

export interface AuthTokensResponse {
  data: {
    access_token: string
    refresh_token: string
  }
}

export interface CurrentUserData {
  id?: number
  user_id: number
  role: string
  name: string
  avatar_url: string
  admin: boolean
}

export interface CurrentUserContextProps {
  currentUser: CurrentUserData | null
  setCurrentUser: (newValue: CurrentUserData | null) => void
  logout: () => void
  fetchCurrentUser: () => Promise<void>
}

export interface ActiveTabContextProps {
  activeTab: string
  setActiveTab: (newValue: string) => void
}

export enum FlashType {
  'info', 'error', 'success', 'warning'
}

export interface FlashData {
  message: string
  type?: FlashType
}

export interface FlashContextProps {
  flash: FlashData
  setFlash: (newValue: any) => void
}

export interface NavbarLinkProps {
  path: string
  className?: string
  activeTabClass?: string
  children: string | JSX.Element | JSX.Element[] | Array<string | JSX.Element>
  setRenderMobileMenu?: React.Dispatch<React.SetStateAction<boolean>>
}

export interface DropdownItemProps {
  action?: (e?: any) => any
  text: string
  link?: string
  icon?: string
}

export interface HomeworkData {
  id: number
  title: string
  description: string
  deadline: Date
  file_upload_deadline: Date
  required: boolean
  graded: boolean
  lesson?: LessonData
  class_units: ClassUnitData[]
  groups: GroupData[]
  homework_solutions: StudentHomeworkSolutionData[]
  students: StudentData[]
}

export interface LessonData {
  id: number
  name: string
  subject: SubjectData
  teachers: TeacherData[]
  semester?: SemesterData
  class_units?: ClassUnitData[]
  groups?: GroupData[]
  grades: GradeData[]
  final_grades: GradeData[]
  homeworks?: HomeworkData[]
}

export interface RoleData {
  id: number
  name: string
}

export interface SemesterData {
  id: number
  year: string
  season: string
}

export interface TeacherData {
  id: number
  user: UserData
  created_at: Date
  updated_at: Date
}

export interface ParentData {
  id: number
  user: UserData
}

export interface StudentData {
  id: number
  user: UserData
  homework_solution: StudentHomeworkSolutionData
  class_unit: ClassUnitData
  created_at: Date
}

export interface ClassUnitData {
  id: number
  name: string
  tutors: TeacherData[]
  students?: StudentData[]
  school_year?: SchoolYearData
  grade: string
  color: string
}

export interface GroupData {
  id: number
  name: string
}

export interface SubjectData {
  id: number
  name: string
  color: string
  created_at: Date
  updated_at: Date
}

export interface StudentHomeworkSolutionData {
  id: number
  created_at: Date
  title: string
  internal_teacher_note: string
  teacher_note: string
  autonomous_work_accepted_at: Date
  teacher_review: string
  homework: HomeworkData
  student: StudentData
  student_answer: string
  solution_files: FileData[]
}

export interface LessonInstanceData {
  id: number
  title: string
  description: string
  starts_at: Date
  ends_at: Date
  assessment_events: any[]
  attendance: null | any
  classroom: ClassroomData
  subject: SubjectData
  lesson: LessonData
  substitute_teacher: TeacherData | null
  teachers: TeacherData[]
  class_units: ClassUnitData[]
  topic: string
}

export interface LessonSlotItemData {
  id: number
  starts_at: string
  ends_at: string
  day_of_week: string
}

export interface ClassroomData {
  id: number
  number: string
  created_at: Date
}
export interface StudentSubjectData {
  id: number
  name: string
}

export interface MessageData {
  id: number
  body: string
  receiver_email?: string
  read: boolean
  is_sender: boolean
  created_at: string
}

export interface ConversationData {
  id: number
  email: string
}

export interface MessagesContextProps {
  messages: MessageData[]
  setMessages: React.Dispatch<React.SetStateAction<MessageData[]>>
  conversations: ConversationData[]
  setConversations: (newValue: ConversationData[]) => void
  receiverEmail: string
  setReceiverEmail: (newValue: string) => void
  messagesPagination: Record<string, any>
  setMessagesPagination: (newValue: any) => void
  messagesPaginationMeta: Record<string, any>
  setMessagesPaginationMeta: (newValue: any) => void
}

export interface InfiniteScrollProps {
  items: Array<Record<string, any>>
  fetchMoreItems: (page: number) => Promise<void>
  renderComponent: (item: any) => React.ReactElement
  paginationMeta: Record<string, any>
  pagination: Record<string, any>
  setPagination: (newValue: any) => void
}

export interface UseApiPostReturnParams {
  sendData: (params?: { callback?: (response: any) => any | null, additionalParams?: Record<any, any>, method?: string, apiUrl?: string }) => any
  sendDataWithFiles: (params: { fileKeys: string[], callback?: (response: any) => any | null, additionalParams?: Record<any, any>, headers?: Record<string, any>, method?: string }) => any
  setInput: (prevInput: any) => any
  validationErrors: ValidationError['details']
  input: Record<any, any>
}

export interface UseApiGetReturnParams {
  fetchData: (query?: Record<any, any>) => any
  setData: (prevData: any) => void
  data: any
  setQuery: (prevQuery: Record<string, string>) => void
  query: Record<string, string>
}

export interface NewsData {
  id: number
  title: string
  content: string
  content_as_text: string
  created_at: Date
  updated_at: Date
  expires_at: Date
  pinned_until: Date
  pinned: boolean
  expired: boolean

  read: boolean
  required_to_read: boolean
  created_by: UserData
  roles: RoleData[]
}

export interface LessonScheduleData {
  id: number
  starts_on: Date
  ends_on: Date
  created_at: Date
}

export interface SearchResultData {
  id: number
  name: string
  status: string
  type: string
  category: string
  avatar_url: string
}

export interface SchoolYearData {
  id: number
  year: string
}

export interface CommentData {
  id: number
  body: string
  created_at: Date
  written_by: UserData
  replies: CommentData[]
}

export interface UserData {
  id: number
  name: string
  email: string
  deactivated_at?: Date
  avatar: FileData
}

export enum ProfileAccess { 'full', 'public' }
export enum Roles { 'student', 'teacher', 'parent', 'admin' }

export interface ProfileData {
  id?: number
  access?: ProfileAccess
  addresses?: AddressData[]
  deactivated_at?: Date
  admin?: boolean
  avatar?: FileData
  avatar_crop_height?: number
  avatar_crop_width?: number
  avatar_crop_x?: number
  avatar_crop_y?: number
  birth_place?: string
  birthday?: Date
  email?: string
  name?: string
  pesel?: string
  public_board?: string
  role?: Roles
  telephone_number?: string
}

export interface AdvancedSearchingFilters {
  category: string
  query: string
  status: string
  type: string
  sort_by: string
}

export interface AdvancedSearchingCounters {
  users: number
  classunits: number
  lessons: number
  sum: number
}

export interface SelectOptions {
  value: string
  text: string
  disabled?: boolean
}

export interface UseParseDateMethods {
  toLocalDate: (date: Date) => string
  toLocalDateWithHour: (date: Date) => string
  toLocalHour: (date: Date) => string
  toInputDate: (date: Date) => string
  toInputDateTime: (date: Date) => string
  toShortHumanizedDate: (date: Date) => string
}

export interface DropdownProps {
  className?: string
  id?: string
  onBlur?: (event: React.FocusEvent<HTMLElement>) => any
  parent?: React.ReactElement
  children: React.ReactElement | React.ReactElement[]
}

export interface SearchChangeCountersContextProps {
  changeCounters: boolean
  setChangeCounters: (prevValue: boolean) => void
}

export interface NotReadRequiredNewsContextProps {
  notReadRequiredNewsCount: number
  setNotReadRequiredNewsCount: (prevNewsCount: number) => void
}

export interface EditorComponentProps {
  content: any
  validationErrors: ValidationError['details']
  setContent: (prevInput: any) => any
  fileUploadURL: string
}

export interface MobileMenuContextProps {
  renderMobileMenu: boolean
  setRenderMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
  renderMobileNavbar: boolean
  setRenderMobileNavbar: React.Dispatch<React.SetStateAction<boolean>>
}

export interface LessonViewContextProps {
  lessonSubjectAndUnits: string
  setLessonSubjectAndUnits: (newValue: string) => void
}

export interface PaginationProps {
  paginationMeta: Record<string, any>
  fetchData: (query: Record<string, any>) => Promise<void>
  filters?: Record<string, any>
  sortParams?: Record<string, any>
}

export interface HomeworksCounters {
  sum: number
  checked: number
  not_sent: number
  to_check: number
  sent: number
}

export interface DatePickerWithHourProps {
  selectedDate: Date | null
  onSelectDate: (date: string) => void
  closeDatePicker?: () => void
}

export enum AddressTypes {
  residential = 'residential',
  registered = 'registered',
  correspondence = 'correspondence',
}

export interface AddressData {
  id: number
  address_type: AddressTypes
  apartment_number: string
  country: string
  postcode: string
  region: string
  street: string
}

export interface CalendarViewState {
  view: 'week' | 'month'
  currentDate: Date
  selectedDate: Date
}

export interface MonthViewProps {
  currentDate: Date
  calendarEvents?: CalendarEventData[]
  homeworks?: HomeworkData[]
  lessonInstances: LessonInstanceData[]
  getWeekdayLabel: (date: Date) => string
  onMonthChange: (newDate: Date) => void
  isMobile: boolean
  onDayClick: (date: Date) => void
}

export interface CalendarLabels {
  dayView: string
  weekView: string
  today: string
  monday: string
  tuesday: string
  wednesday: string
  thursday: string
  friday: string
  saturday: string
  sunday: string
}

export interface CalendarViewProps {
  currentDate: Date
  events: Array<CalendarEventData | LessonInstanceData | HomeworkData>
  onEventClick: (event: CalendarEventData | LessonInstanceData | HomeworkData, e: React.MouseEvent) => void
}

export interface LessonInstancePopoverContentProps {
  lessonInstance: LessonInstanceData
  onClose: () => void
  isMobile: boolean
  attendanceIcons: Record<string, React.ReactElement>
}

export interface CalendarEventPopoverContentProps {
  event: CalendarEventData
  onClose: () => void
  isMobile: boolean
}

export interface NavigationHeaderProps {
  weekBoundaries: {
    weekStart: Date
    weekEnd: Date
  }
  viewState: CalendarViewState
  onPrev: () => void
  onNext: () => void
  onToday: () => void
  onViewChange: (view: CalendarViewState['view']) => void
}

export interface UseCalendarNavigationReturn {
  weekBoundaries: {
    weekStart: Date
    weekEnd: Date
  }
  viewState: CalendarViewState
  setViewState: React.Dispatch<React.SetStateAction<CalendarViewState>>
  handleDateChange: (newDate: Date) => void
  handlePrev: () => void
  handleNext: () => void
  handleToday: () => void
}

export interface PopoverState<T = any> {
  isOpen: boolean
  data: T | null
  position: {
    top: number
    left: number
  } | null
}

export interface PopoverProps<T = any> {
  data: T
  position: {
    top: number
    left: number
  } | null
  onClose: () => void
}

export interface LessonInstancePopoverProps extends PopoverProps<LessonInstanceData> {
  attendanceIcons: {
    presence: JSX.Element
    absence: JSX.Element
    justified_absence: JSX.Element
    school_presence: JSX.Element
    late: JSX.Element
  }
}

export interface TimeSlotEventsProps {
  calendarRef: React.RefObject<HTMLDivElement>
  isMobile: boolean
  weekBoundaries: { weekStart: Date, weekEnd: Date }
  lessonInstances: LessonInstanceData[]
  lessonSlots: LessonSlotItemData[]
  calendarEvents: CalendarEventData[]
  homeworks: HomeworkData[]
  handleLessonClick: (event: LessonInstanceData, e: React.MouseEvent<Element>) => void
  handleCalendarEventClick: (event: CalendarEventData, e: React.MouseEvent<HTMLButtonElement>) => void
  handleHomeworkClick: (homework: HomeworkData, e: React.MouseEvent<Element>) => void
  getWeekdayLabel: (date: Date) => string
  toLocalHour: (date: Date) => string
  toLocalDateWithHour: (date: Date) => string
  activeLessonId?: number
  activeCalendarEventId?: number
  activeHomeworkId?: number
}

export interface GradeData {
  id: number
  value: string
  note: string
  created_at: Date
  grade_type: string
  student: StudentData
  graded_by: TeacherData
}

export interface GradeTemplate {
  id: number
  name: string
  grades: GradeData[]
}

export interface GradeCategory {
  id: number
  name: string
  grade_templates: GradeTemplate[]
}

export interface TeacherLessonGradesProps {
  categories: GradeCategory[]
  fetchLessonData: (query?: Record<any, any>) => any
  fetchGradesData: (query?: Record<any, any>) => any
  lessonId: string
}

export interface GradeGroupModalProps {
  isOpen: boolean
  onClose: () => void
  groupName: string
  onGroupNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSave: () => void
  onDelete?: () => void
  editingGroupId: number | null
}

export interface GradeTemplateModalProps {
  isOpen: boolean
  onClose: () => void
  templateName: string
  onTemplateNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSave: () => void
  onDelete?: () => void
  editingTemplateId: number | null
}

export interface GradeEditModalProps {
  isOpen: boolean
  onClose: () => void
  isMobile: boolean
  selectedGrade: {
    id?: number
    studentId: number
    gradeTemplateId: number
    gradeType: string
    value?: string
    note?: string
  } | null
  onGradeChange: (grade: any) => void
  onSave: () => void
  students: StudentData[]
  t: (key: string) => string
}

export interface TeachersListProps {
  lesson: LessonData
  t: (key: string) => string
}

export interface ClassUnitsListProps {
  lesson: LessonData
  t: (key: string) => string
}

export interface StudentCellProps {
  student: StudentData
  index: number
}

export interface GradeCellProps {
  student: StudentData
  template: GradeTemplate
  grade?: GradeData
  editingCell: {
    studentId: number
    templateId: number
    value: string
  } | null
  onGradeClick: () => void
  onGradeChange: (value: string) => void
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onOpenGradeEdit: () => void
}

export interface ChevronIconProps {
  collapsed: boolean
}

export interface StudentLessonGradesProps {
  grades: GradeCategory[]
  lesson: LessonData
}

export interface FlattenedGrade extends Omit<GradeData, 'student'> {
  template_name: string
  group_name: string
}
