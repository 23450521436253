import React from 'react'
import type { GradeGroupModalProps } from '../../../../constants/types'

const GradeGroupModal: React.FC<GradeGroupModalProps> = ({
  isOpen,
  onClose,
  groupName,
  onGroupNameChange,
  onSave,
  onDelete,
  editingGroupId
}) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-lg">
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-bold text-gray-800">Dodaj grupę ocen</h3>
          <button
            type="button"
            onClick={onClose}
            className="text-gray-500 hover:text-gray-900"
          >
            &times;
          </button>
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Nazwa grupy ocen</label>
          <input
            type="text"
            className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Wprowadź nazwę grupy"
            value={groupName}
            onChange={onGroupNameChange}
          />
        </div>

        <div className="flex justify-end items-center gap-x-2 mt-4">
          <button
            type="button"
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 hover:bg-gray-50"
            onClick={onClose}
          >
            Zamknij
          </button>
          {editingGroupId && onDelete && (
            <button
              type="button"
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700"
              onClick={onDelete}
            >
              Usuń
            </button>
          )}
          <button
            type="button"
            className="text-blue-800 hover:text-white flex gap-2 justify-center py-2 px-4 border border-blue-800 rounded-lg hover:bg-blue-800 items-center"
            onClick={onSave}
          >
            Zapisz
          </button>
        </div>
      </div>
    </div>
  )
}

export default GradeGroupModal
