import React, { type ReactElement } from 'react'
import { Roles } from '../../../constants/types'
import { API_ADMIN_STUDENTS_PATH } from '../../../constants/apiRoutes'
import { ADMIN_STUDENTS_PATH } from '../../../constants/adminRoutes'
import ProfileForm from '../../../components/profiles/ProfileForm'
import { useTranslation } from 'react-i18next'

const NewStudentView = (): ReactElement => {
  const { t } = useTranslation('translations')

  return (
    <div className="grid lg:w-1/2" >
      <div className="px-5 lg:px-14 py-8">
        <h1 className="text-2xl lg:text-3xl text-left pb-12">{t('admin.students.new_header')}</h1>
        <ProfileForm url={API_ADMIN_STUDENTS_PATH} profileData={{ role: Roles.student }} redirect={ADMIN_STUDENTS_PATH }/>
      </div>
    </div>
  )
}

export default NewStudentView
