export const STUDENTS_NAMESPACE_PATH = '/students'
export const STUDENT_DASHBOARD_PATH = '/students/dashboard'
export const STUDENT_HOMEWORKS_PATH = '/students/homeworks'
export const STUDENT_SUBJECTS_PATH = '/students/subjects'
export const STUDENT_HOMEWORK_SOLUTION_EDIT_PATH = '/students/homeworks/:homeworkId/homeworkSolutions/:id'
export const STUDENT_HOMEWORK_SOLUTION_PATH = '/students/homeworks/:homeworkId/homeworkSolutions/:id/show'
export const STUDENT_NEW_HOMEWORK_SOLUTION_PATH = '/students/homeworks/:homeworkId/homeworkSolutions/new'
export const STUDENT_GRADES_PATH = '/students/grades'
export const STUDENT_LESSONS_PATH = '/students/lessons'
export const STUDENT_LESSON_PATH = '/students/lessons/:id'
export const STUDENT_LESSON_GRADES_PATH = '/students/lessons/:id/grades'
