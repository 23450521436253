export const TEACHERS_NAMESPACE_PATH = '/teachers'
export const TEACHER_DASHBOARD_PATH = '/teachers/dashboard'
export const TEACHER_HOMEWORKS_PATH = '/teachers/homeworks'
export const TEACHER_LESSON_HOMEWORKS_PATH = '/teachers/lessons/:id/homeworks'
export const TEACHER_HOMEWORK_EDIT_PATH = '/teachers/homeworks/:id'
export const TEACHER_NEW_HOMEWORK_PATH = '/teachers/homeworks/new'
export const TEACHER_HOMEWORK_SOLUTION_EDIT_PATH = '/teachers/homework_solutions/:id'
export const TEACHER_HOMEWORK_PATH = '/teachers/homeworks/:id/homework_solutions'
export const TEACHER_LESSONS_PATH = '/teachers/lessons'
export const TEACHER_LESSON_PATH = '/teachers/lessons/:id'
export const TEACHER_GRADES_PATH = '/teachers/grades'
export const TEACHER_LESSON_GRADES_PATH = '/teachers/lessons/:id/grades'
