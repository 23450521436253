import React from 'react'
import { useTranslation } from 'react-i18next'
import type { MonthViewProps, CalendarEventData } from '../../../constants/types'

export const MonthView: React.FC<MonthViewProps> = ({
  currentDate,
  calendarEvents,
  homeworks = [],
  lessonInstances = [],
  getWeekdayLabel,
  onDayClick,
  isMobile
}): JSX.Element => {
  const { t } = useTranslation('translations')

  const getDateWithoutTime = (date: Date): Date =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate())

  const generateCalendarDays = (): Array<{ date: Date, isCurrentMonth: boolean }> => {
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth()
    const firstDay = new Date(year, month, 1)
    const lastDay = new Date(year, month + 1, 0)

    let startOfWeek = firstDay.getDay() - 1
    if (startOfWeek < 0) startOfWeek = 6

    const days: Array<{ date: Date, isCurrentMonth: boolean }> = []

    for (let i = startOfWeek; i > 0; i--) {
      const prevDay = new Date(year, month, -i + 1)
      days.push({ date: prevDay, isCurrentMonth: false })
    }

    for (let i = 1; i <= lastDay.getDate(); i++) {
      days.push({ date: new Date(year, month, i), isCurrentMonth: true })
    }

    const nextMonthStartDay = days.length % 7
    for (let i = 1; i <= (nextMonthStartDay > 0 ? 7 - nextMonthStartDay : 0); i++) {
      const nextDay = new Date(year, month + 1, i)
      days.push({ date: nextDay, isCurrentMonth: false })
    }

    return days
  }

  const chunkedDays = (
    days: Array<{ date: Date, isCurrentMonth: boolean }>
  ): Array<Array<{ date: Date, isCurrentMonth: boolean }>> => {
    const chunks: Array<Array<{ date: Date, isCurrentMonth: boolean }>> = []
    for (let i = 0; i < days.length; i += 7) {
      chunks.push(days.slice(i, i + 7))
    }
    return chunks
  }

  const getWeekMultiDayEvents = (
    weekDays: Array<{ date: Date, isCurrentMonth: boolean }>
  ): CalendarEventData[] => {
    const weekStart = getDateWithoutTime(weekDays[0].date)
    const weekEnd = getDateWithoutTime(weekDays[6].date)

    return (calendarEvents ?? []).filter(event => {
      const eventStart = getDateWithoutTime(new Date(event.starts_at))
      const eventEnd = getDateWithoutTime(new Date(event.ends_at))
      const diffTime = eventEnd.getTime() - eventStart.getTime()
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      return diffDays > 0 && eventStart <= weekEnd && eventEnd >= weekStart
    })
  }

  const getSingleDayEvents = (date: Date): CalendarEventData[] => {
    return (calendarEvents ?? []).filter(event => {
      const eventStart = getDateWithoutTime(new Date(event.starts_at))
      const eventEnd = getDateWithoutTime(new Date(event.ends_at))
      const diffTime = eventEnd.getTime() - eventStart.getTime()
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      return diffDays === 0 && getDateWithoutTime(date).getTime() === eventStart.getTime()
    })
  }

  const getVisibleEventDates = (
    event: CalendarEventData,
    weekDays: Array<{ date: Date, isCurrentMonth: boolean }>
  ): { visibleStart: Date, visibleEnd: Date } => {
    const eventStart = getDateWithoutTime(new Date(event.starts_at))
    const eventEnd = getDateWithoutTime(new Date(event.ends_at))
    const weekStart = getDateWithoutTime(weekDays[0].date)
    const weekEnd = getDateWithoutTime(weekDays[6].date)

    const visibleStart = eventStart < weekStart ? weekStart : eventStart
    const visibleEnd = eventEnd > weekEnd ? weekEnd : eventEnd

    return { visibleStart, visibleEnd }
  }

  const getColumnForDate = (
    date: Date,
    weekDays: Array<{ date: Date, isCurrentMonth: boolean }>
  ): number => {
    return weekDays.findIndex(day =>
      getDateWithoutTime(day.date).getTime() === getDateWithoutTime(date).getTime()
    )
  }

  const getSpanBetweenDates = (start: Date, end: Date): number => {
    const diffTime = end.getTime() - start.getTime()
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  }

  const calculateEventRows = (events: CalendarEventData[], weekDays: Array<{ date: Date, isCurrentMonth: boolean }>): CalendarEventData[][] => {
    const rows: CalendarEventData[][] = []
    const sortedEvents = [...events].sort((a, b) => new Date(a.starts_at).getTime() - new Date(b.starts_at).getTime())

    sortedEvents.forEach(event => {
      const eventStart = getDateWithoutTime(new Date(event.starts_at))
      const eventEnd = getDateWithoutTime(new Date(event.ends_at))

      let rowIndex = 0
      let foundRow = false

      while (!foundRow && rowIndex < rows.length) {
        const rowEvents = rows[rowIndex]
        const hasOverlap = rowEvents.some(existingEvent => {
          const existingStart = getDateWithoutTime(new Date(existingEvent.starts_at))
          const existingEnd = getDateWithoutTime(new Date(existingEvent.ends_at))
          return !(eventEnd < existingStart || eventStart > existingEnd)
        })

        if (!hasOverlap) {
          foundRow = true
          rows[rowIndex].push(event)
        }
        rowIndex++
      }

      if (!foundRow) {
        rows.push([event])
      }
    })

    return rows
  }

  const isToday = (date: Date): boolean => {
    const today = new Date()
    return getDateWithoutTime(date).getTime() === getDateWithoutTime(today).getTime()
  }

  const getEventClassName = (eventType?: string, isCurrentMonth: boolean = true): string => `
    truncate
    border
    border-gray-200
    rounded
    ${isMobile ? 'text-[9px] px-1' : 'text-xs px-2'}
    ${eventType === 'normal' ? 'bg-yellow-100' : ''}
    ${eventType === 'holiday' ? 'bg-green-100' : ''}
    ${eventType === undefined ? 'bg-gray-100' : ''}`

  const days = generateCalendarDays()
  const weeks = chunkedDays(days)
  const getWeekdayShortLabel = (date: Date): string => {
    const weekdays = [
      t('calendar.days_of_week.monday_short'),
      t('calendar.days_of_week.tuesday_short'),
      t('calendar.days_of_week.wednesday_short'),
      t('calendar.days_of_week.thursday_short'),
      t('calendar.days_of_week.friday_short'),
      t('calendar.days_of_week.saturday_short'),
      t('calendar.days_of_week.sunday_short')
    ]
    const dayIndex = date.getDay()
    const adjustedIndex = dayIndex === 0 ? 6 : dayIndex - 1
    return weekdays[adjustedIndex]
  }

  return (
    <div className="grid border border-gray-200">
      <div className="grid grid-cols-7">
        {Array.from({ length: 7 }).map((_, index) => {
          const labelDate = new Date(currentDate)
          labelDate.setDate(labelDate.getDate() - labelDate.getDay() + index + 1)

          return (
            <div key={index} className="p-2 text-center font-semibold text-xs text-gray-500 border-r border-gray-200 last:border-r-0">
              {isMobile
                ? getWeekdayShortLabel(labelDate)
                : getWeekdayLabel(labelDate)
              }
            </div>
          )
        })}
      </div>

      {weeks.map((weekDays, weekIndex) => (
        <div key={weekIndex} className="grid">
          <div className="grid grid-cols-7">
            {weekDays.map(({ date, isCurrentMonth }) => (
              <div
                key={date.toISOString()}
                className={`min-h-[20px] border-t border-b border-r border-gray-200 last:border-r-0 p-2 cursor-pointer ${isCurrentMonth ? 'bg-white' : 'bg-gray-50'}`}
                onClick={() => { onDayClick(date) }}
              >
                <div className={`text-xs ${isCurrentMonth ? 'text-gray-900' : 'text-gray-400'} ${isMobile ? 'text-center' : ''}`}>
                  <div className={`flex items-center justify-center ${!isMobile ? 'w-6' : ''} h-6 ${
                    isToday(date) ? 'bg-blue-200 rounded-full' : ''
                  }`}>
                    {date.getDate()}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {getWeekMultiDayEvents(weekDays).length > 0 && (
            <div className="relative pt-1 min-h-[20px] border-gray-200">
              <div className="absolute inset-0 grid grid-cols-7 pointer-events-none">
                {weekDays.map(({ date }, index) => (
                  <div key={index} className="border-r border-gray-200 last:border-r-0" />
                ))}
              </div>
              <div className="relative pt-1 pb-1">
                {calculateEventRows(getWeekMultiDayEvents(weekDays), weekDays).map((rowEvents, rowIndex) => (
                  <div key={rowIndex} className="relative h-7">
                    {rowEvents.map(event => {
                      const { visibleStart, visibleEnd } = getVisibleEventDates(event, weekDays)
                      const startCol = getColumnForDate(visibleStart, weekDays)
                      const span = getSpanBetweenDates(visibleStart, visibleEnd) + 1

                      return (
                        <button
                          key={event.id}
                          className={`absolute cursor-default ${getEventClassName(event.event_type)}`}
                          style={{
                            left: `${(startCol * 100) / 7}% + 4px`,
                            width: `calc(${(span * 100) / 7}% - 8px)`,
                            top: '2px',
                            height: '24px',
                            marginLeft: '4px'
                          }}
                        >
                          {event.event_type === 'normal' ? event.title : t('calendar.holiday')}
                        </button>
                      )
                    })}
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="grid grid-cols-7">
            {weekDays.map(({ date, isCurrentMonth }) => (
              <div key={date.toISOString()} className="space-y-1 border-r last:border-r-0 border-gray-200 p-1 min-h-[80px]">
                {(() => {
                  const dayEvents = getSingleDayEvents(date)
                  const dayHomeworks = homeworks.filter(event => {
                    const eventDeadline = new Date(event.deadline)
                    return getDateWithoutTime(eventDeadline).getTime() === getDateWithoutTime(date).getTime()
                  })
                  const MAX_VISIBLE_EVENTS = 4
                  const assessmentEvents = lessonInstances
                    .filter(instance => {
                      const lessonDate = getDateWithoutTime(new Date(instance.starts_at))
                      return getDateWithoutTime(date).getTime() === lessonDate.getTime()
                    })
                    .filter(instance => instance.assessment_events?.length > 0)
                    .map(instance => instance.assessment_events)
                    .flat()
                  const allEvents = [...dayHomeworks, ...assessmentEvents, ...dayEvents]
                  const hiddenEventsCount = Math.max(0, allEvents.length - MAX_VISIBLE_EVENTS)

                  return (
                    <>
                      {dayHomeworks.slice(0, MAX_VISIBLE_EVENTS).map(event => (
                        <button
                          key={event.id}
                          className={`w-full min-h-[24px] py-1 truncate border rounded bg-red-100 ${isMobile ? 'text-[9px] px-1' : 'text-xs px-2'}`}
                          onClick={(e) => {
                            e.stopPropagation()
                            onDayClick(date)
                          }}
                        >
                          <div className={`w-full flex gap-2 truncate ${isMobile ? 'text-center' : 'text-left'}`}>
                            {!isMobile && (
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-4 w-4 shrink-0">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                              </svg>
                            )}
                            <div className="truncate">{event.lesson?.subject?.name}</div>
                          </div>
                        </button>
                      ))}
                      {lessonInstances
                        .filter(instance => {
                          const lessonDate = getDateWithoutTime(new Date(instance.starts_at))
                          return getDateWithoutTime(date).getTime() === lessonDate.getTime()
                        })
                        .filter(instance => instance.assessment_events?.length > 0)
                        .slice(0, MAX_VISIBLE_EVENTS - dayHomeworks.length)
                        .map(instance =>
                          instance.assessment_events.map(assessment => (
                            <button
                              key={`${instance.id}-${assessment.id}`}
                              className={`w-full min-h-[24px] py-1 truncate border border-l-4 border-l-${instance.subject?.color}  rounded bg-white ${isMobile ? 'text-[9px] px-1' : 'text-xs px-2'}`}
                              onClick={(e) => {
                                e.stopPropagation()
                                onDayClick(date)
                              }}
                            >
                              <div className={`w-full truncate ${isMobile ? 'text-center' : 'text-left'}`}>
                                {assessment.title}
                              </div>
                            </button>
                          ))
                        )}
                      {dayEvents.slice(0, MAX_VISIBLE_EVENTS - dayHomeworks.length).map(event => (
                        <button
                          key={event.id}
                          className={`w-full min-h-[24px] ${getEventClassName(event.event_type, isCurrentMonth)}`}
                          onClick={(e) => {
                            e.stopPropagation()
                            onDayClick(date)
                          }}
                        >
                          <div className={`w-full truncate ${isMobile ? 'text-center' : 'text-left'}`}>
                            {event.event_type === 'normal' ? event.title : t('calendar.holiday')}
                          </div>
                        </button>
                      ))}
                      {hiddenEventsCount > 0 && (
                        <div className={`text-gray-500 pt-1 text-left ${isMobile ? 'text-[8px]' : 'text-xs'}`}>
                          {t('calendar.more')} {hiddenEventsCount}
                        </div>
                      )}
                    </>
                  )
                })()}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default MonthView
