import React, { type ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'

const ClassUnitsFilters = ({
  query,
  setQuery,
  fetchClassUnits
}: {
  fetchClassUnits: (query: Record<string, string>) => void
  query: Record<string, string>
  setQuery: (prevQuery: Record<string, string>) => void
}): ReactElement => {
  const { t } = useTranslation('translations')
  const formRef = useRef<HTMLFormElement>(null)

  const debouncedSearch = debounce((searchValue: string) => {
    const newQuery = { ...query, name: searchValue }
    setQuery(newQuery)
    fetchClassUnits(newQuery)
  }, 300)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const searchValue = formData.get('search') as string

    const newQuery = { ...query, name: searchValue }
    setQuery(newQuery)
    fetchClassUnits(newQuery)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const searchValue = e.target.value
    if (searchValue === '') {
      const newQuery = { ...query, name: '' }
      setQuery(newQuery)
      fetchClassUnits(newQuery)
    } else {
      debouncedSearch(searchValue)
    }
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit} className="w-full sm:w-64 md:w-72">
      <div className="relative flex-1 min-w-[200px]">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          type="search"
          name="search"
          className="block w-full p-2.5 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500"
          placeholder={t('admin.class_units.search_placeholder')}
          onChange={handleSearchChange}
          defaultValue={query.name}
          aria-label={t('admin.class_units.search_placeholder')}
        />
      </div>
    </form>
  )
}

export default ClassUnitsFilters
